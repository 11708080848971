/**
 * @file   src\components\Layout.tsx
 * @brief  This component used as a layout for the content which can be replaced.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { React, Link, Col, Container, Row, Outlet, Sidebar, Menu } from './ThirdPartyComponents';
import '../styles/Layout.scss';
import Header from './Header';
import Logo from '../assests/images/logo.svg';
import MobileMenu from '../assests/icons/MobileMenu';
import CloseMenu from '../assests/icons/Close';
import CAMenu from './CAMenu';
import AdminMenu from './AdminMenu';
import { getDataFromStorage } from '../utils/helper';
import { STORAGE_USER } from '../utils/constants';
import { RoleTypes } from '../utils/enums';

// Layout is common for all pages. Decides layout of the web pages
const Layout = () => {
  // Access role from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);
  const role = userInfo?.roleId || 0;
  // Manage states
  const [collapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);

  return (
    <Container fluid>
      <Row>
        <Sidebar collapsed={collapsed} toggled={toggled} onBackdropClick={() => setToggled(false)} customBreakPoint="1024px" className="main-menu">
          <div className="logo-container">
            <Link to="/home">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <Menu className="menulist">
            {Number(role) === RoleTypes.CA ? (
              <CAMenu toggleMenu={(val: boolean) => setToggled(val)} role={Number(role)} />
            ) : (
              <AdminMenu toggleMenu={(val: boolean) => setToggled(val)} role={Number(role)} />
            )}
          </Menu>
        </Sidebar>

        <Col className="main-container">
          <button type="button" className="sb-button" onClick={() => setToggled(!toggled)}>
            {toggled ? <CloseMenu /> : <MobileMenu />}.
          </button>
          <Header />
          <div className="content-area-main">
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
