/**
 * @file   src\store\slices\reportSlice.ts
 * @brief  This file is responsible for creating report related slices to call actions and state management.
 * @date   Oct, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { IReports } from '../../interfaces/reportInterface';
import { getCaAttendanceReport, exportAttendance, revertAllReportState } from '../actions/reportAction';

// Initial state.
const reportState: IReports = {
  isFetchAttendanceReportSuccess: false,
  isFetchAttendanceReportLoading: false,
  fetchAttendanceReportErrorCode: 0,
  fetchAttendanceReportMessage: null,
  attendanceData: null,
  totalAttendance: 0,
  isExportAttendanceSuccess: false,
  isExportAttendanceLoading: false,
  exportAttendanceErrorCode: 0,
  exportAttendanceMessage: null,
  pdfData: null,
};

// Config report slice.
export const reportSlice = createSlice({
  name: 'report',
  initialState: reportState,
  reducers: {
    resetReportState: () => reportState,
  },
  extraReducers(builder) {
    // ********************* Start of get all CA attendance report listing action state handling. *********************
    // Get all CA attendance report default state.
    builder.addCase(getCaAttendanceReport.pending, (state) => {
      state.isFetchAttendanceReportSuccess = false;
      state.isFetchAttendanceReportLoading = true;
      state.fetchAttendanceReportErrorCode = 0;
      state.fetchAttendanceReportMessage = '';
    });
    // Get all CA attendance report success state.
    builder.addCase(getCaAttendanceReport.fulfilled, (state, action) => {
      const { status, info } = action.payload;
      const error = action.payload?.error || 0;
      state.isFetchAttendanceReportLoading = false;
      state.isFetchAttendanceReportSuccess = true;
      state.fetchAttendanceReportErrorCode = error;
      state.fetchAttendanceReportMessage = status || '';
      state.attendanceData = info?.attendance || [];
      state.totalAttendance = info?.total || 0;
    });
    // Get all CA attendance report error state.
    builder.addCase(getCaAttendanceReport.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isFetchAttendanceReportSuccess = false;
      state.isFetchAttendanceReportLoading = false;
      state.fetchAttendanceReportErrorCode = errcode;
      state.fetchAttendanceReportMessage = error || '';
    });
    // ********************* End of get all CA attendance report listing action state handling. *********************

    // ********************* Start of export CA attendance as pdf action state handling. *********************
    // Export CA attendance as pdf default state.
    builder.addCase(exportAttendance.pending, (state) => {
      state.isExportAttendanceSuccess = false;
      state.isExportAttendanceLoading = true;
      state.exportAttendanceErrorCode = 0;
      state.exportAttendanceMessage = '';
    });
    // Export CA attendance as pdf success state.
    builder.addCase(exportAttendance.fulfilled, (state, action) => {
      state.isExportAttendanceLoading = false;
      state.isExportAttendanceSuccess = true;
      state.exportAttendanceErrorCode = 0;
      state.pdfData = action.payload;
      state.exportAttendanceMessage = null;
    });
    // Export CA attendance as pdf error state.
    builder.addCase(exportAttendance.rejected, (state, action: any) => {
      const { errcode, error } = action.payload?.error || 0;
      state.isExportAttendanceSuccess = false;
      state.isExportAttendanceLoading = false;
      state.exportAttendanceErrorCode = errcode;
      state.exportAttendanceMessage = error || '';
    });
    // ********************* End of export CA attendance as pdf action state handling. *********************

    // ********************* Clear all report states to initial state action. *********************
    builder.addCase(revertAllReportState, (state) => {
      state.isFetchAttendanceReportSuccess = false;
      state.isFetchAttendanceReportLoading = false;
      state.fetchAttendanceReportErrorCode = 0;
      state.fetchAttendanceReportMessage = null;
      state.isExportAttendanceSuccess = false;
      state.isExportAttendanceLoading = false;
      state.exportAttendanceErrorCode = 0;
      state.exportAttendanceMessage = null;
    });
  },
});
// Export report actions.
export const { resetReportState } = reportSlice.actions;
// Export reducer.
export default reportSlice.reducer;
