import React from 'react';

const ReportsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.062" height="25.79" viewBox="0 0 19.062 25.79">
      <g id="Group_16259" data-name="Group 16259" transform="translate(-42.29 -328.539)">
        <path
          id="_290137_clipboard_document_file_list_report_icon"
          data-name="290137_clipboard_document_file_list_report_icon"
          d="M23.82,26.79H9.243A2.243,2.243,0,0,1,7,24.547V4.925A2.243,2.243,0,0,1,9.243,2.682h1.682a.561.561,0,1,1,0,1.121H9.243A1.122,1.122,0,0,0,8.121,4.925V24.547a1.122,1.122,0,0,0,1.121,1.121H23.82a1.122,1.122,0,0,0,1.121-1.121V4.925A1.122,1.122,0,0,0,23.82,3.8H22.138a.561.561,0,1,1,0-1.121H23.82a2.243,2.243,0,0,1,2.243,2.243V24.547A2.243,2.243,0,0,1,23.82,26.79ZM22.7,5.485a.56.56,0,0,1-.561.561H10.925a.561.561,0,1,1,0-1.121,2.074,2.074,0,0,0,1.121-.561l1.121-1.121a1.559,1.559,0,0,1,1.121-.561h.561a1.682,1.682,0,0,1,3.364,0h.561a1.429,1.429,0,0,1,1.121.561l1.121,1.121a2.074,2.074,0,0,0,1.121.561A.56.56,0,0,1,22.7,5.485ZM16.531,2.121a.56.56,0,0,0-.561.561h1.121A.561.561,0,0,0,16.531,2.121Zm3.007,2.243A1.349,1.349,0,0,0,18.519,3.8H14.544a1.348,1.348,0,0,0-1.019.561l-.51.561h7.033Z"
          transform="translate(35.29 327.539)"
          fill="#1b1658"
          fillRule="evenodd"
        />
        <rect id="Rectangle_572" data-name="Rectangle 572" width="7" height="1" transform="translate(46 336)" fill="#1b1658" />
        <rect id="Rectangle_573" data-name="Rectangle 573" width="7" height="1" transform="translate(46 338)" fill="#1b1658" />
        <rect id="Rectangle_574" data-name="Rectangle 574" width="12" height="1" transform="translate(46 341)" fill="#1b1658" />
        <rect id="Rectangle_575" data-name="Rectangle 575" width="12" height="1" transform="translate(46 343)" fill="#1b1658" />
        <rect id="Rectangle_576" data-name="Rectangle 576" width="12" height="1" transform="translate(46 345)" fill="#1b1658" />
        <rect id="Rectangle_577" data-name="Rectangle 577" width="5" height="1" transform="translate(53 349)" fill="#1b1658" />
      </g>
    </svg>
  );
};

export default ReportsIcon;
