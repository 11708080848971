/**
 * @file   src\utils\apiUrls.ts
 * @brief  This file is responsible for defining api urls.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

// Authorization api urls.
export enum AuthApi {
  LOGIN = '/api/ca/login',
  SIGNUP = '/api/ca/signUp',
  ADMIN_LOGIN = '/api/admin/login',
  ADMIN_SIGNUP = '/api/admin/signup',
}

// User api urls.
export enum UserApi {
  PROFILE_SETUP = '/api/ca/setProfile',
  UPDATE_PROFILE = '/api/common/auth/ca/update/profile',
  LOGOUT = '/logout',
  GET_PROFILE = '/api/ca/profile',
  PROFILE_ADMIN_SETUP = '/api/admin/set/profile',
  UPDATE_ADMIN_PROFILE = '/api/admin/update/profile',
  PROFILE_EDIT_OTP_DENT = '/api/common/auth/sendEditPhoneOTP',
  GET_ADMIN_PROFILE = '/api/admin/get/profile',
  GET_ALL_USERS = '/api/admin/users/list',
  GET_SCHEDULER_DETAILS = '/api/admin/scheduler/details',
  UPDATE_SCHEDULER_DETAILS = '/api/admin/scheduler/update/details',
  ACTIVATE_OR_INACATIVATE_SCHEDULER_USERS = '/api/admin/scheduler/change/status',
}

// Manage schedule api urls.
export enum ManageScheduleApi {
  GET_ALL_SHIFTS = '/api/common/auth/allShifts',
  GET_ALL_SHIFT_TYPES = '/api/ca/allShiftTypes',
  UNASSIGN_CA = '/api/ca/unassignShift',
  ASSIGN_CA = '/api/ca/assignShifts',
  GET_SHIFT_DETAILS = '/api/ca/shiftDetailsById',
  CALL_OUT_SHIFT = '/api/ca/callout',
  GET_USER_SHIFTS = '/api/ca/usershifts',
}

// Manage swap api urls.
export enum ManageSwapApi {
  SWAP_SHIFT = '/api/ca/postSwapRequest',
  GET_SHIFT_REQUEST = '/api/ca/mySwapRequests',
  DELETE_SWAP_REQUEST = '/api/ca/deleteSwap',
  UPDATE_SWAP_REQUEST_STATUS = '/api/ca/updateSwapStatus',
  RESEND_SWAP_REQUEST = '/api/ca/resendSwap',
  GET_SWAP_REQUEST_BY_ADMIN = '/api/admin/shift/swap-requests',
  APPROVE_DECLINE_SWAP_BY_ADMIN = 'approveorDeclineByAdmin',
  GET_DECLINE_SWAP_REASONS = '/api/admin/decline/swap/reasons',
  APPROVE_SWAP_REQUEST = '/api/admin/shift/swap-requests/approve',
  DECLINE_SWAP_REQUEST = '/api/admin/shift/swap-requests/decline',
}

// Manage leave api urls.
export enum ManageLeaveApi {
  GET_ALL_LEAVE_REQUEST = '/api/ca/allLeaves',
  APPLY_LEAVE_REQUEST = '/api/ca/applyLeave',
  DELETE_LEAVE_REQUEST = '/api/ca/deleteLeaveRequest',
  APPROVE_LEAVE_REQUEST = '/api/admin/ca/leave/approve',
  DECLINE_LEAVE_REQUEST = '/api/admin/ca/leave/decline',
  GET_LEAVE_DETAILS = 'getLeaveDetails',
  GET_DECLINE_LEAVE_REASONS = '/api/admin/decline/leave/reasons',
}

// Manage CA users api urls.
export enum ManageCAUsersApi {
  GET_ALL_CA_USERS = '/api/admin/ca/list',
  ACTIVATE_OR_INACATIVATE_CA_USERS = '/api/admin/ca/change/status',
  GET_CA_DETAILS = '/api/admin/ca/profile',
  UPDATE_CA_DETAILS = '/api/common/auth/ca/update/profile',
}

// Manage call out shifts api urls.
export enum ManageCallOutShiftApi {
  GET_ALL_CALLOUT_SHIFTS = '/api/admin/shift/callout-shifts',
  GET_CA_FOR_CALLON = '/api/ca/caListForCallOut',
  SEND_CALLON_MESSAGES_TO_CA = '/api/ca/notify',
  EXPORT_CALLON_SHIFT = '/api/admin/export/callout/cas/list',
  CALLOUT_SHIFT_COUNT = 'api/common/callOutCount',
}

// Manage admin(scheduler and manager) schedule api urls.
export enum ManageAdminScheduleApi {
  GET_ALL_SHIFTS = '/api/common/auth/allShiftsWithSummary',
  ADD_NEW_SHIFT = '/api/common/auth/addShift',
  EDIT_SHIFT = '/api/common/auth/editShift',
  ADD_RECURRING_SHIFT = '/api/common/auth/addReccuringShift',
  GET_ASSIGNED_OR_UNASSIGEND_CA_LIST = '/api/admin/ca/assign/list',
  PUBLISH_SHIFT = '/api/admin/shift/publish',
  DELETE_SHIFT = '/api/admin/shift/remove',
  ASSIGN_CA_TO_A_SHIFT = 'assignCA',
  GET_ALL_CA_WITH_THEIR_SHEDULE_AND_LEAVE_DETAILS = 'getAllCAScheduleAndLeaveDetails',
  NOSHOW_ONCALL_UPDATE = '/api/admin/shift/update/noshow',
  NOTIFY_CA = '/api/admin/shift/notify/ca',
}

// Common api urls.
export enum CommonApi {
  SEND_OTP = '/api/common/sendOTP',
  VERIFY_OTP = '/api/common/verifyOTP',
  SEND_FORGOT_PASSWORD_OTP = '/api/common/sendPasswordResetOTP',
  REFRESH_TOKEN = '/api/common/auth/refresh/access-token',
  RESET_PASSWORD = '/api/common/resetPassword',
  CHANGE_PASSWORD = '/api/common/auth/changePassword',
  GET_SCHEDULES_AND_LEAVE_DETAILS = '/api/common/auth/schedules/roster/view',
  ISSHIFT_HOLIDAY = '/api/common/auth/is-holiday',
}

// State clearing action types for all the slices.
export enum KKIActionTypes {
  REVERT_ALL_USER_STATE = 'REVERT_ALL_USER_STATE',
  REVERT_ALL_COMMON_STATE = 'REVERT_ALL_COMMON_STATE',
  REVERT_ALL_SCHEDULE_STATE = 'REVERT_ALL_SCHEDULE_STATE',
  REVERT_ALL_SWAP_STATE = 'REVERT_ALL_SWAP_STATE',
  REVERT_ALL_AUTH_STATE = 'REVERT_ALL_AUTH_STATE',
  REVERT_ALL_LEAVE_STATE = 'REVERT_ALL_LEAVE_STATE',
  REVERT_ALL_ADMIN_SCHEDULE_STATE = 'REVERT_ALL_ADMIN_SCHEDULE_STATE',
  REVERT_ALL_HOLIDAY_STATE = 'REVERT_ALL_HOLIDAY_STATE',
  REVERT_ALL_MANAGE_CA_STATE = 'REVERT_ALL_MANAGE_CA_STATE',
  REVERT_ALL_CALLOUT_STATE = 'REVERT_ALL_CALLOUT_STATE',
  REVERT_ALL_LD_STATE = 'REVERT_ALL_LD_STATE',
  REVERT_ALL_REPORT_STATE = 'REVERT_ALL_REPORT_STATE',
}

// Manage holiday urls.
export enum ManageHolidayApi {
  GET_HOLIDAY_LIST = '/api/admin/holidays/list',
  ADD_HOLIDAY = '/api/admin/holidays/add/new',
  DELETE_HOLIDAY = '/api/admin/holidays/delete',
  UPDATE_HOLIDAY = '/api/admin/holidays/edit',
}

// Manage LD urls//manager code
export enum ManageLDApi {
  GET_ALL_LD_USERS = '/api/admin/ld/reasons/list',
  REPORT_LD_REASON = '/api/admin/ld/report/reason',
  EXPORT_LD_REASON_LIST = '/api/admin/export/ld/reasons/list',
  GET_LD_REASONS = '/api/admin/decline/ld/reasons',
}

// Report api urls
export enum ReportApi {
  GET_CA_ATTENDANCE = '/api/admin/attendance/report/list',
  GET_ATTENDANCE_LIST_PDF = '/api/admin/export/attendance/report',
}
