/**
 * @file   src\containers\CA\Profile.tsx
 * @brief  Profile page.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, Row, Card, Link, useEffect, moment } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import Edit from '../../../assests/icons/Edit';
import Verified from '../../../assests/images/verify-icon.svg';
import { CARoutePath, ColorGroups } from '../../../utils/enums';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { getProfile, revertAllUserState } from '../../../store/actions/userAction';
import { formatPhoneNumber, getKeyIndicators } from '../../../utils/helper';
import { DATE_FORMAT_DD_MMM_YYYY, NOT_AVAILABLE_TEXT } from '../../../utils/constants';
import Loader from '../../../components/Loader';

const Profile = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isProfileFetchLoading, profileInfo } = useAppSelector((state: RootState) => state.user);

  // Component initial loading sideEffect to fetch profile api.
  useEffect(() => {
    dispatch(getProfile());
    return () => {
      dispatch(revertAllUserState());
    };
  }, []);

  return (
    <div>
      <div className="pageheader d-flex justify-content-between">
        <h1>{Strings.Header.Profile}</h1>
        <Link to={CARoutePath.EditProfile} className="d-flex">
          <Edit />
          {Strings.Button.Edit}
        </Link>
      </div>
      <div className="profile-main">
        <Row>
          <Col xl={6} lg={6} sm={12} className="profile-sub">
            <Card>
              <Card.Body>
                <Col className="mb-0">
                  <Row>
                    <Col>{Strings.Label.Name}</Col>
                    <Col>
                      <span>{profileInfo?.firstName ? `${profileInfo?.firstName} ${profileInfo.lastName}` : ''}</span>
                    </Col>
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-sub">
            <Card>
              <Card.Body>
                <Col className="mb-0">
                  <Row>
                    <Col>{Strings.Label.ColorGroup}</Col>
                    <Col>
                      <span className={`color-group ${profileInfo?.colorGroup ? ColorGroups[profileInfo.colorGroup]?.toLowerCase() : ''}`}>
                        {profileInfo?.colorGroup ? ColorGroups[profileInfo.colorGroup] : ''}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-sub">
            <Card>
              <Card.Body>
                <Col className="mb-0">
                  <Row>
                    <Col>{Strings.Label.Email} </Col>
                    <Col>
                      <span>{profileInfo?.email || ''}</span>
                    </Col>
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-sub">
            <Card>
              <Card.Body>
                <Col className="mb-0">
                  <Row>
                    <Col>{Strings.Label.Mobile} </Col>
                    <Col>
                      <span>
                        {profileInfo?.phone && formatPhoneNumber(profileInfo?.phone)}
                        <img src={Verified} alt="verified" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-sub">
            <Card>
              <Card.Body>
                <Col className="mb-0">
                  <Row>
                    <Col>{Strings.SetupProfile.EmpId} </Col>
                    <Col>
                      <span>{profileInfo?.empId || NOT_AVAILABLE_TEXT}</span>
                    </Col>
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-sub">
            <Card>
              <Card.Body>
                <Col className="mb-0">
                  <Row>
                    <Col>{Strings.SetupProfile.HireDate} </Col>
                    <Col>
                      <span>{profileInfo?.hireDate ? moment(profileInfo?.hireDate).local().format(DATE_FORMAT_DD_MMM_YYYY) : NOT_AVAILABLE_TEXT}</span>
                    </Col>
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={6} sm={12} className="profile-sub">
            <Card>
              <Card.Body>
                <Col className="mb-0">
                  <Row>
                    <Col xs="auto">{Strings.SetupProfile.ReceiveSMS}</Col>
                    <Col>
                      <span>{profileInfo?.isSubscribed ? Strings.SetupProfile.Subscribed : Strings.SetupProfile.NotSubscribed}</span>
                    </Col>
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Col className="key-indicators">
        <Col className="heading">{Strings.Label.KeyIndicators}</Col>
        <Col className="points">
          <p className="fw-md">
            {getKeyIndicators({
              floatStaff: profileInfo?.floatStaff,
              fullTime: profileInfo?.fullTime,
              lighDuty: profileInfo?.lightDuty,
              volunteer: profileInfo?.volunteer,
              student: profileInfo?.student,
              restrictedOt: profileInfo?.restrictedOt,
              partTime24: profileInfo?.partTime24,
              partTime36: profileInfo?.partTime36,
            })}
          </p>
        </Col>
      </Col>
      {isProfileFetchLoading && <Loader />}
    </div>
  );
};
export default Profile;
