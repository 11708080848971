/**
 * @file   src\components\EmployeeCard.tsx
 * @brief  Employee details card.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import DateTimePicker from 'react-datetime-picker';
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Accordion, Card, Col, Row, Button, useAccordionButton, useNavigate, useLocation, useState, useEffect, moment } from './ThirdPartyComponents';
import { CustomToggleProps, IEmployeeCard, IObject } from '../interfaces/generalInterface';
import KKICheckbox from './KKICheckbox';
import Arrow from '../assests/icons/DownArrow';
import Time from '../assests/icons/Time';
import useAccordionCollapse from '../hooks/useAccordionCollapse';
import { DATE_FORMAT_YYYY_MM_DD_HH_MM_SS, NUMBER_0, NUMBER_1, NUMBER_2, NUMBER_3, NUMBER_4, NUMBER_40, STORAGE_USER } from '../utils/constants';
import { ColorGroups, MyScheduleActions, NoShow, AdminRoutePath, RoleTypes, NavigationToEmployeeCardFrom } from '../utils/enums';
import KKISelect from './KKISelect';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { calculateHourDifference, formatPhoneNumber, getDataFromStorage, getKeyIndicators, isCurrentTimeIsBeforeShiftStartTime } from '../utils/helper';
import {
  CHECKIN_TIME_MUST_AFTER_SHIFT_START_TIME,
  CHECKIN_TIME_MUST_BEFORE_CHECKOUT_TIME,
  CHECKOUT_TIME_MUST_AFTER_CHECKIN_TIME,
  CHECKOUT_TIME_MUST_BEFORE_SHIFT_END_TIME,
} from '../messages/validationMessages';

const CustomToggle = ({ children, eventKey, callback }: CustomToggleProps) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback(eventKey);
  });

  return (
    <button type="button" className="accordion-btn" onClick={decoratedOnClick}>
      {children}
    </button>
  );
};
// NoShow status options
const show = [
  { label: NoShow[NoShow.Show].replace(/_/g, ' '), value: NoShow.Show.toString() },
  { label: NoShow[NoShow.No_Show].replace(/_/g, ' '), value: NoShow.No_Show.toString() },
  { label: NoShow[NoShow.Left_Early].replace(/_/g, ' '), value: NoShow.Left_Early.toString() },
  { label: NoShow[NoShow.Show_Late].replace(/_/g, ' '), value: NoShow.Show_Late.toString() },
];

const EmployeeCard = ({
  caDetails,
  shiftDetails,
  isFrom, // 1= from details page, 2= from assign page, 3 = callout page
  onCheckboxChange,
  onButtonClick,
  onNoShowChange,
  onOnCallChange,
  selectedEmployees,
  isSelectAllChecked,
}: IEmployeeCard) => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Create location object to access location info.
  const location = useLocation();

  // Get the collapsed state using the custom hook
  const { isCollapsed, toggleCollapse } = useAccordionCollapse();

  // Access userInfo from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);
  // Loggined user role
  const roleId = userInfo?.roleId;

  // Initialize component stat variables.
  const [selectedShow, setSelectedShow] = useState<IObject | null>(null);
  const [onCallValues, setOnCallValues] = useState<boolean>(false);
  const [checkInTime, setCheckInTime] = useState<Date | null>(null);
  const [checkOutTime, setCheckOutTime] = useState<Date | null>(null);
  const [checkInErrorMessage, setCheckInErrorMessage] = useState<string>('');
  const [checkOutErrorMessage, setCheckOutErrorMessage] = useState<string>('');

  // Setting the initial NoShow value based on the employee's details.
  useEffect(() => {
    const selectedShowValue = show.find((option) => option.value === caDetails?.isNoShow?.toString()) || null;
    setSelectedShow(selectedShowValue);
    setOnCallValues(caDetails?.isOnCall || false);
    const parsedCheckInTime = caDetails?.checkInTime ? new Date(caDetails.checkInTime) : null;
    const parsedCheckOutTime = caDetails?.checkOutTime ? new Date(caDetails.checkOutTime) : null;

    setCheckInTime(parsedCheckInTime);
    setCheckOutTime(parsedCheckOutTime);
    setCheckInErrorMessage('');
    setCheckOutErrorMessage('');
  }, [caDetails]);

  // Handle checkbox change event and notify the parent component
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onCheckboxChange) {
      onCheckboxChange(e.target.checked, caDetails);
    }
  };

  // NoShow status change event hander.
  const handleNoShowStatusChange = (val: IObject) => {
    const noShowStatus = val.value;
    const selectedOption = show.find((option) => option.value === noShowStatus?.toString()) || null;
    setSelectedShow(selectedOption);
    setCheckInTime(null);
    setCheckOutTime(null);
    setCheckInErrorMessage('');
    setCheckOutErrorMessage('');
    if (caDetails?.caId && onNoShowChange) {
      onNoShowChange(Number(caDetails?.caId), Number(noShowStatus), NUMBER_0.toString(), null, null);
    }
  };

  // OnCall checkbox change event handler
  const handleOnCallChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOnCallValues(e.target.checked);
    if (caDetails?.caId && onOnCallChange) {
      onOnCallChange(Number(caDetails?.caId), e.target.checked, Number(caDetails?.colorGroup));
    }
  };

  // Handle check-in time change
  const handleCheckInChange = (value: Date | null) => {
    if (value) {
      setCheckInErrorMessage('');
      const shiftTime = moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      const enteredTime = moment(value).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      const shiftEndTime = moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      if (moment(enteredTime).isSameOrAfter(moment(shiftTime)) && moment(enteredTime).isSameOrBefore(moment(shiftEndTime))) {
        if (checkOutTime && moment(enteredTime).isAfter(moment(checkOutTime))) {
          setCheckInErrorMessage(CHECKIN_TIME_MUST_BEFORE_CHECKOUT_TIME);
          setCheckOutErrorMessage('');
        } else {
          setCheckInErrorMessage('');
          setCheckOutErrorMessage('');
        }
      } else {
        setCheckInErrorMessage(CHECKIN_TIME_MUST_AFTER_SHIFT_START_TIME);
      }
      setCheckInTime(value);
    } else {
      setCheckInTime(null);
    }
    if (caDetails?.caId && selectedShow && onNoShowChange) {
      const hourDiff = calculateHourDifference(checkInTime, checkOutTime);
      onNoShowChange(Number(caDetails.caId), Number(selectedShow.value), hourDiff, moment(value).toISOString(), moment(checkOutTime).toISOString());
    }
  };

  // Handle check-out time change
  const handleCheckOutChange = (value: Date | null) => {
    if (value) {
      setCheckOutErrorMessage('');
      const shiftTime = moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      const enteredTime = moment(value).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      const shiftEndTime = moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      if (moment(enteredTime).isSameOrAfter(moment(shiftTime)) && moment(enteredTime).isSameOrBefore(moment(shiftEndTime))) {
        if (checkInTime && moment(enteredTime).isBefore(moment(checkInTime))) {
          setCheckOutErrorMessage(CHECKOUT_TIME_MUST_AFTER_CHECKIN_TIME);
          setCheckInErrorMessage('');
        } else {
          setCheckOutErrorMessage('');
          setCheckInErrorMessage('');
        }
      } else {
        setCheckOutErrorMessage(CHECKOUT_TIME_MUST_BEFORE_SHIFT_END_TIME);
      }
      setCheckOutTime(value);
    } else {
      setCheckOutTime(null);
    }
    if (caDetails?.caId && selectedShow && onNoShowChange) {
      const hourDiff = calculateHourDifference(checkInTime, checkOutTime);
      onNoShowChange(Number(caDetails.caId), Number(selectedShow.value), hourDiff, moment(checkInTime).toISOString(), moment(value).toISOString());
    }
  };

  // Method to determine on-call and mandatory OT status
  const getOnCallAndMandatoryOTStatus = (callOutType: number | undefined) => {
    let isOnCall = false;
    let isMandatoryOT = false;
    const type = callOutType ?? NUMBER_0;
    switch (type) {
      case NUMBER_1:
      case NUMBER_2:
        isOnCall = false;
        isMandatoryOT = false;
        break;
      case NUMBER_3:
        isOnCall = true;
        isMandatoryOT = false;
        break;
      case NUMBER_4:
        isOnCall = false;
        isMandatoryOT = true;
        break;
      default:
        break;
    }

    return { isOnCall, isMandatoryOT };
  };

  // Get the on-call and mandatory OT status
  const { isOnCall, isMandatoryOT } = getOnCallAndMandatoryOTStatus(shiftDetails?.callOutType);

  return (
    <Accordion activeKey={!isCollapsed ? '0' : null} className="shiftcard-main">
      <Card className={isFrom === NavigationToEmployeeCardFrom.CA_Assign_Page && caDetails?.assignedHours > NUMBER_40 ? 'time-exceed overflow-visible' : 'overflow-visible'}>
        <Card.Header className={`d-flex justify-content-between align-items-center ${isCollapsed ? 'collapse' : ''}`}>
          <Col className="d-flex align-items-center">
            {(isFrom === NavigationToEmployeeCardFrom.CA_Assign_Page && roleId === NUMBER_2) || isFrom === NavigationToEmployeeCardFrom.Callout_Page ? (
              <div className="me-2 position-relative z-1">
                <KKICheckbox
                  id={`checkbox-${caDetails?.caId.toString()}`}
                  name="kkicheckbox"
                  checkBoxType="checkbox"
                  checked={isSelectAllChecked || selectedEmployees?.find((employee) => employee?.caId === caDetails?.caId) !== undefined}
                  onChange={handleCheckboxChange}
                />
              </div>
            ) : (
              ''
            )}
            {caDetails?.firstName || caDetails?.lastName ? `${caDetails?.firstName ?? ''} ${caDetails?.lastName ?? ''}`.trim() : null}
            {caDetails?.isMandatoryOT ? <span className="ps-1">({Strings.Label.MandatoryOT})</span> : ''}
          </Col>
          <div>
            <Col xs="auto" className="d-flex cardheader-right align-items-center">
              {isFrom === NavigationToEmployeeCardFrom.Shift_Details_Page && roleId === RoleTypes.Scheduler && (shiftDetails?.onCallRequired ?? NUMBER_0) > NUMBER_0 ? (
                <div className="ms-2 position-relative z-1 fw-sb">
                  <KKICheckbox id={caDetails?.caId.toString()} name="kkicheckbox" checkBoxType="checkbox" label="On Call" checked={onCallValues} onChange={handleOnCallChange} />
                </div>
              ) : (
                ''
              )}
              {isFrom === NavigationToEmployeeCardFrom.CA_Assign_Page || isFrom === NavigationToEmployeeCardFrom.Callout_Page ? (
                <div className="fw-sb">Work Hours: {isFrom === NavigationToEmployeeCardFrom.Callout_Page ? caDetails?.workHours : caDetails?.actualHours || NUMBER_0} Hrs</div>
              ) : (
                ''
              )}
            </Col>
            <CustomToggle eventKey="0" callback={toggleCollapse}>
              <Arrow />
            </CustomToggle>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="shift-details">
            <Col className="shift-details-sub">
              {shiftDetails &&
                shiftDetails?.published &&
                isFrom === NavigationToEmployeeCardFrom.Shift_Details_Page &&
                roleId === RoleTypes.Scheduler &&
                !isCurrentTimeIsBeforeShiftStartTime(shiftDetails?.shiftFromTime || '') && (
                  <Row className="mb-3">
                    <Col className="fw-normal">
                      <KKISelect
                        id="Status"
                        label="Attendance Type"
                        name="Status"
                        className="custom-select"
                        placeholder={Strings.Label.CallOutCategory}
                        searchvalue={false}
                        options={show}
                        value={selectedShow}
                        onSelectChange={handleNoShowStatusChange}
                      />
                    </Col>
                    <Col xl={4} className="date-time">
                      <label htmlFor="timing" className="form-label fw-normal">
                        Check-in Time
                        <input type="hidden" id="timing" />
                      </label>
                      <DateTimePicker
                        id="checkInTime"
                        calendarIcon={
                          <div onClick={(e) => e.stopPropagation()}>
                            <Time />
                          </div>
                        }
                        disableClock
                        value={checkInTime}
                        onChange={handleCheckInChange}
                        disabled={Number(selectedShow?.value) === NoShow.No_Show || !selectedShow?.value}
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                      />
                      {checkInErrorMessage && <span className="error">{checkInErrorMessage}</span>}
                    </Col>
                    <Col className="date-time">
                      <label htmlFor="timing" className="form-label fw-normal">
                        Check-out Time
                        <input type="hidden" id="timing" />
                      </label>
                      <DateTimePicker
                        id="checkOutTime"
                        calendarIcon={
                          <div onClick={(e) => e.stopPropagation()}>
                            <Time />
                          </div>
                        }
                        disableClock
                        value={checkOutTime}
                        onChange={handleCheckOutChange}
                        disabled={Number(selectedShow?.value) === NoShow.No_Show || !selectedShow?.value}
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                      />
                      {checkOutErrorMessage && <span className="error">{checkOutErrorMessage}</span>}
                    </Col>
                  </Row>
                )}
              <Row>
                <Col xs={6} lg={6} xl={6} xxl={4} className="card-cell-mob">
                  {Strings.Label.EmpId}: <span>{caDetails?.empId}</span>
                </Col>
                <Col xs={6} lg={6} xl={6} xxl={4} className="card-cell-mob">
                  <span className="lg-ps-2">{Strings.Label.ColorGroup}</span>
                  <span>{ColorGroups[caDetails?.colorGroup]}</span>
                </Col>
                <Col xs={6} lg={6} xl={6} xxl={4} className={isFrom === NavigationToEmployeeCardFrom.Callout_Page ? 'card-cell-mob' : ''}>
                  <span className="lg-ps-2">{Strings.Label.Contact}</span>
                  <span>{caDetails?.phone ? formatPhoneNumber(caDetails?.phone) : ''}</span>
                </Col>
                {isFrom === NavigationToEmployeeCardFrom.Callout_Page ? (
                  <>
                    <Col
                      xs={6}
                      lg={6}
                      xl={6}
                      xxl={4}
                      className={`mt-min-xxl-16 pt-min-xxl-16 bt-min-xxl ${isFrom === NavigationToEmployeeCardFrom.Callout_Page ? 'card-cell-mob' : ''}`}
                    >
                      <span className="lg-ps-2">{Strings.Label.OnCall}</span>
                      <span>{isOnCall ? Strings.Label.Yes : Strings.Label.No}</span>
                    </Col>
                    <Col xs={6} lg={6} xl={6} xxl={4} className="mt-min-xxl-16 pt-min-xxl-16 bt-min-xxl">
                      <span className="lg-ps-2">{Strings.Label.MandatoryOT}</span>
                      <span>{isMandatoryOT ? Strings.Label.Yes : Strings.Label.No}</span>
                    </Col>
                    <Col xs={6} lg={6} xl={6} xxl={4} className="mt-min-xxl-16 pt-min-xxl-16 bt-min-xxl">
                      <span className="lg-ps-2">{Strings.CAKeyIndicators.OnPremise}</span>
                      <span>{caDetails?.onPrem ? Strings.Label.Yes : Strings.Label.No}</span>
                    </Col>
                  </>
                ) : (
                  ''
                )}
              </Row>
            </Col>
            <Row className="align-items-center">
              <Col xs={12} md>
                {Strings.Label.KeyIndicators}:
                <span className="fw-sb">
                  {' '}
                  {getKeyIndicators({
                    floatStaff: caDetails?.floatStaff,
                    fullTime: caDetails?.fullTime,
                    lighDuty: caDetails?.lightDuty,
                    volunteer: caDetails?.volunteer,
                    student: caDetails?.student,
                    restrictedOt: caDetails?.restrictedOt,
                    partTime24: caDetails?.partTime24,
                    partTime36: caDetails?.partTime36,
                  })}
                </span>
              </Col>
              <Col xs={12} md="auto" className={isFrom !== NUMBER_1 ? 'd-none' : 'text-end'}>
                <Button
                  variant="outline-primary"
                  type="submit"
                  onClick={() => navigate({ pathname: `${AdminRoutePath.SCHViewSchedules}/${caDetails.caId}` }, { state: { prevUrl: location.pathname } })}
                >
                  {Strings.Button.ViewSchedules}
                </Button>
                {roleId === RoleTypes.Scheduler && (
                  <Button variant="primary" type="submit" onClick={() => onButtonClick?.(caDetails?.caId, MyScheduleActions.Unassign)}>
                    {Strings.Button.UnAssign}
                  </Button>
                )}

                {shiftDetails?.published && isCurrentTimeIsBeforeShiftStartTime(shiftDetails?.shiftFromTime || '') ? (
                  <Button variant="primary" type="submit" onClick={() => onButtonClick?.(caDetails?.caId, MyScheduleActions.Callout)}>
                    {Strings.Shift.Button.CallOut}
                  </Button>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default EmployeeCard;
