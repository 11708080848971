/**
 * @file   src\containers\Login.tsx
 * @brief  Login page.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Button, useState, Col, useNavigate, useEffect } from '../components/ThirdPartyComponents';
import Strings from '../assests/strings/Strings.json';
import KKISelect from '../components/KKISelect';
import KKIInputMask from '../components/KKIInputMask';
import KKIPassword from '../components/KKIPassword';
import TopLogo from '../assests/images/logo.svg';
import PassowrdIcon from '../assests/icons/PasswordHide';
import PassowrdShow from '../assests/icons/PasswordShow';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RoleTypes, RoutePath, CARoutePath, AdminRoutePath } from '../utils/enums';
import { IObject } from '../interfaces/generalInterface';
import { IAuthRequest } from '../interfaces/authInterface';
import { SIGNIN_SCHEMA } from '../validations/authSchema';
import { validateForm } from '../utils/validationHelper';
import { revertAllAuthState, validateAdminUser, validateUser } from '../store/actions/authAction';
import { getDataFromStorage, setStorageData } from '../utils/helper';
import { HTTP_STATUS_200, NUMBER_30, PHONE_COUNTRY_CODE, SESSION_TYPE_TEXT, STORAGE_ROLE, STORAGE_USER } from '../utils/constants';
import { MessageToaster } from '../utils/toastUtils';
import { RootState } from '../store';
import { revertAllUserState } from '../store/actions/userAction';

// Toast object creation.
const toast = new MessageToaster();

const Login = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Navigate object creation.
  const navigate = useNavigate();

  // Extracting state from auth slice
  const { isSuccess, errorCode, message, userInfo } = useAppSelector((state: RootState) => state.auth);
  // Initialize component stat variables.
  const [roleId, setRoleId] = useState<IObject | null>({ label: RoleTypes[RoleTypes.CA], value: RoleTypes.CA.toString() });
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorFields, setErrorFields] = useState<IObject>({});

  // Inital loading to check alreday logined or not and Component unmound loading.
  useEffect(() => {
    // Check if user info exists in storage
    const userData = getDataFromStorage(STORAGE_USER);

    if (userData) {
      // If the user has a role of 'CA', navigate to CA Schedule, otherwise go to Manage Schedule
      if (userData?.roleId === RoleTypes?.CA) {
        navigate(CARoutePath.Home);
      } else {
        navigate(AdminRoutePath.ManageSchedule);
      }
    }
    // Access url parameters.
    const queryParams = new URLSearchParams(window.location.search);
    const paramType = queryParams && queryParams.get('type') ? queryParams.get('type') : null;
    if (paramType === SESSION_TYPE_TEXT) {
      toast.toastError(Strings.Text.SessionOut);
      if (window.location.search) {
        // Remove the query string.
        navigate(window.location.pathname, { replace: true });
      }
    }
    // Cleanup function to revert user and auth states on component unmount
    return () => {
      dispatch(revertAllUserState());
      dispatch(revertAllAuthState());
    };
  }, []);

  // Effect hook to handle navigation and toast notifications based on authentication state.
  useEffect(() => {
    if (isSuccess && errorCode === HTTP_STATUS_200 && message && userInfo) {
      // Save user info in local storage
      setStorageData(STORAGE_USER, JSON.stringify(userInfo));
      // If roleId is CA, fetch profile, otherwise redirect based on profile completion
      if (Number(userInfo.roleId) === RoleTypes.CA && userInfo?.is_profile_completed) {
        // Redirect CA user to Home
        navigate(CARoutePath.Home);
      } else if (userInfo?.is_profile_completed) {
        navigate(AdminRoutePath.ManageSchedule);
      } else {
        navigate(RoutePath.SetupProfile);
      }
    } else if (!isSuccess && errorCode > HTTP_STATUS_200 && message) {
      setPassword('');
      dispatch(revertAllAuthState());
      toast.toastError(message);
    }
  }, [isSuccess, errorCode, message, userInfo, roleId, navigate]);

  // Role select change event handler.
  const onRoleSelectChange = async (val: IObject) => {
    setStorageData(STORAGE_ROLE, JSON.stringify(val.value));
    setRoleId(val);
    const errorresult = await validateForm({ roleId: val?.value ? Number(val.value) : 0 }, SIGNIN_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Input phone element change event handler.
  const onPhoneInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const inputVal = value ? value.replace(/\D/g, '') : '';
    setPhone(inputVal);
  };

  // Input password element change event handler.
  const onPasswordInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setPassword(value);
  };

  // Signin button submit event handler.
  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const loginForm: IAuthRequest = {
      roleId: roleId?.value ? Number(roleId.value) : RoleTypes.Role,
      phone,
      password,
    };
    const errorresult = await validateForm(loginForm, SIGNIN_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      if (Number(loginForm.roleId) === RoleTypes.CA) {
        dispatch(validateUser({ phone: `${PHONE_COUNTRY_CODE}${phone}`, password }));
      } else {
        dispatch(validateAdminUser({ roleId: Number(loginForm.roleId), phone: `${PHONE_COUNTRY_CODE}${phone}`, password }));
      }
    }
  };

  // Show/hide password.
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const roles = [
    { label: RoleTypes[RoleTypes.CA], value: RoleTypes.CA.toString() },
    { label: RoleTypes[RoleTypes.Scheduler], value: RoleTypes.Scheduler.toString() },
    { label: RoleTypes[RoleTypes.Manager], value: RoleTypes.Manager.toString() },
  ];

  return (
    <Col className="cs-form-sec" xl={8} lg={8} md={10} sm={11} xs={10}>
      <div className="kki-logo-wrap">
        <img src={TopLogo} alt="kki" width={149} height={130} />
      </div>
      <h2 className="text-center">{Strings.CsLogin.SubHeadText}</h2>
      <h3 className="text-center">{Strings.CsLogin.Signin}</h3>
      <form name="loginform" onSubmit={onSubmit}>
        <KKISelect
          id="loginselect"
          name="loginselect"
          className="custom-select"
          options={roles}
          value={roleId}
          placeholder={Strings.CsLogin.PlaceHolder}
          onSelectChange={onRoleSelectChange}
          searchvalue={false}
          alert={errorFields?.roleId || ''}
        />
        <KKIInputMask
          id="mobnumber"
          name="phone"
          type="text"
          mask="999-999-9999"
          placeholder={Strings.CsLogin.MobileNumber}
          onChange={onPhoneInputChange}
          alert={errorFields?.phone || ''}
          verified={false}
        />
        <KKIPassword
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          icon={<PassowrdIcon />}
          showpass={showPassword ? <PassowrdIcon togglePassword={() => togglePassword()} /> : <PassowrdShow togglePassword={() => togglePassword()} />}
          placeholder={Strings.CsLogin.Password}
          onChange={onPasswordInputChange}
          alert={errorFields?.password || ''}
          maxLength={NUMBER_30}
          value={password}
        />

        <div className="d-flex align-items-center justify-content-end mb-4">
          <a href={void 0} className="fg-pwd-link" onClick={() => navigate(RoutePath.ForgotPassword)}>
            {Strings.CsLogin.Forgot}
          </a>
        </div>

        <Button variant="primary" type="submit" className="mb-30 w-100">
          {Strings.CsLogin.PrBtntText}
        </Button>
        <a href={void 0} className="create-account-link d-block text-center" onClick={() => navigate(RoutePath.Signup)}>
          {Strings.CsLogin.CreateAccount}
        </a>
      </form>
    </Col>
  );
};

export default Login;
