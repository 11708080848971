/**
 * @file   src\store\actions\manageCallOutShiftAction.ts
 * @brief  This file is responsible for creating asynchronous api call for call out shift management slice.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ICallOutShiftListRequest, ICallOnMessageRequest, ICallOutCAListRequest } from '../../interfaces/callOutShiftInterface';
import axios from '../../utils/apiConfig';
import { KKIActionTypes, ManageCallOutShiftApi } from '../../utils/apiUrls';
import { buildQueryString } from '../../utils/helper';

// Get all call out shifts api call.
export const getCallOutShifts = createAsyncThunk('/manageCallOutShift/getCallOutShifts', async (request: ICallOutShiftListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageCallOutShiftApi.GET_ALL_CALLOUT_SHIFTS}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get all call on CA list api call.
export const getCallOnCAList = createAsyncThunk('/manageCallOutShift/getCallOnCAList', async (request: ICallOutCAListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageCallOutShiftApi.GET_CA_FOR_CALLON}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Send call on messages to CA api call.
export const sendCallOnMessages = createAsyncThunk('/manageCallOutShift/sendCallOnMessages', async (request: ICallOnMessageRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageCallOutShiftApi.SEND_CALLON_MESSAGES_TO_CA, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Export pdf for call out shifts/CA and call on CAs api call.
export const exportCallOutList = createAsyncThunk('/manageCallOutShift/exportCallOutList', async (request: ICallOutCAListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageCallOutShiftApi.EXPORT_CALLON_SHIFT}?${buildQueryString(request)}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf', // adjust based on API requirements
      },
    });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all callout states to initial sate action.
export const revertAllCalloutState = createAction(KKIActionTypes.REVERT_ALL_CALLOUT_STATE);
