/**
 * @file   src\validations\manageScheduleSchema.ts
 * @brief  This file is responsible for defining schedule/shifts related form validation schemas.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import {
  CREATESHIFT_BLUE_REQUIRED,
  CREATESHIFT_CA_REQUIRED,
  CREATESHIFT_ENDDATE_REQUIRED,
  CREATESHIFT_ENDTIME_REQUIRED,
  CREATESHIFT_LD_REQUIRED,
  CREATESHIFT_RED_REQUIRED,
  CREATESHIFT_SHIFTDAYS_REQUIRED,
  CREATESHIFT_SHIFT_REQUIRED,
  CREATESHIFT_STARTDATE_REQUIRED,
  CREATESHIFT_STARTTIME_REQUIRED,
  CREATESHIFT_WHITE_REQUIRED,
  CREATESHIFT_YELLOW_REQUIRED,
} from '../messages/validationMessages';
import { CreateShiftTypes } from '../utils/enums';

// Create shift form validation schema.
export const CREATE_SHIFT_SCHEMA = yup.object({
  shiftTypeId: yup.number().required(CREATESHIFT_SHIFT_REQUIRED).min(1, CREATESHIFT_SHIFT_REQUIRED),
  requiredCA: yup.number().required(CREATESHIFT_CA_REQUIRED).min(1, CREATESHIFT_CA_REQUIRED),
  isYellowRequired: yup.boolean(),
  isBlueRequired: yup.boolean(),
  isRedRequired: yup.boolean(),
  isWhiteRequired: yup.boolean(),
  isLDRequired: yup.boolean(),
  requiredYellow: yup.number().when('isYellowRequired', {
    is: true,
    then: (schema) => schema.required(CREATESHIFT_YELLOW_REQUIRED).min(1, CREATESHIFT_YELLOW_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if isYellowRequired is false.
  }),
  requiredBlue: yup.number().when('isBlueRequired', {
    is: true,
    then: (schema) => schema.required(CREATESHIFT_BLUE_REQUIRED).min(1, CREATESHIFT_BLUE_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if isBlueRequired is false.
  }),
  requiredRed: yup.number().when('isRedRequired', {
    is: true,
    then: (schema) => schema.required(CREATESHIFT_RED_REQUIRED).min(1, CREATESHIFT_RED_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if isRedRequired is false.
  }),
  requiredWhite: yup.number().when('isWhiteRequired', {
    is: true,
    then: (schema) => schema.required(CREATESHIFT_WHITE_REQUIRED).min(1, CREATESHIFT_WHITE_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if isRedRequired is false.
  }),
  requiredLD: yup.number().when('isLDRequired', {
    is: true,
    then: (schema) => schema.required(CREATESHIFT_LD_REQUIRED).min(1, CREATESHIFT_LD_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if isLDRequired is false.
  }),
  createShiftType: yup.number(),
  shiftStartTime: yup.string().trim().required(CREATESHIFT_STARTTIME_REQUIRED),
  shiftEndTime: yup.string().trim().required(CREATESHIFT_ENDTIME_REQUIRED),
  shiftStartDate: yup.string().trim().required(CREATESHIFT_STARTDATE_REQUIRED),
  shiftEndDate: yup.string().when('createShiftType', {
    is: Number(CreateShiftTypes.Recurring),
    then: (schema) => schema.required(CREATESHIFT_ENDDATE_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if createShiftType is not recurring.
  }),
  shiftDays: yup.array().when('createShiftType', {
    is: Number(CreateShiftTypes.Recurring),
    then: (schema) => schema.required(CREATESHIFT_SHIFTDAYS_REQUIRED).min(1, CREATESHIFT_SHIFTDAYS_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if createShiftType is not recurring.
  }),
});
