/**
 * @file   src\store\actions\reportAction.ts
 * @brief  This file is responsible for creating asynchronous api call for CA report slice.
 * @date   Oct, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/apiConfig';
import { IAttendanceReportRequest, IAttendancePdfReportRequest } from '../../interfaces/reportInterface';
import { ReportApi, KKIActionTypes } from '../../utils/apiUrls';
import { buildQueryString } from '../../utils/helper';

// Get CA attendance report api call.
export const getCaAttendanceReport = createAsyncThunk('/report/getCaAttendance', async (request: IAttendanceReportRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ReportApi.GET_CA_ATTENDANCE}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Export CA attendance as pdf api call.
export const exportAttendance = createAsyncThunk('/report/exportAttendance', async (request: IAttendancePdfReportRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ReportApi.GET_ATTENDANCE_LIST_PDF}?${buildQueryString(request)}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf', // adjust based on API requirements
      },
    });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all report states to initial state action.
export const revertAllReportState = createAction(KKIActionTypes.REVERT_ALL_REPORT_STATE);
