/**
 * @file   src\components\RosterTable.tsx
 * @brief  Component for listing all schedules assigned for CA who is logged in.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from '../assests/strings/Strings.json';
import '../styles/RosterCard.scss';
import { IGroupedScheduleItem, IScheduleItem } from '../interfaces/scheduleInterface';
import { Col, Row, Dropdown, moment, useNavigate } from './ThirdPartyComponents';
import More from '../assests/icons/MobileMenu';
import OnCall from '../assests/icons/OnCall';
import DownFill from '../assests/icons/DownFill';
import { LeaveStatus, LeaveTypes, MyScheduleActions, ScheduleTabs } from '../utils/enums';
import { isCurrentTimeIsBeforeShiftStartTime, isEmpty } from '../utils/helper';
import { DATE_FORMAT_DD_MMM_HHMMA, DATE_FORMAT_HHMMA, DATE_FORMAT_YYYY_MM_DD, NUMBER_0, NIGHT_SHIFTS, DAY_SHIFTS, MID_SHIFTS } from '../utils/constants';
import { ILeaveDetails } from '../interfaces/leaveInterface';

const RosterTable = ({ date, shifts, leaves, holidays, filterItems, onButtonClick }: IGroupedScheduleItem) => {
  // Navigate object creation.
  const navigate = useNavigate();

  return (
    <Row className="roster-details-main">
      <Col className="roster-date d-flex align-items-center">
        <div>
          <div>{moment(date, DATE_FORMAT_YYYY_MM_DD).format('ddd').toUpperCase()}</div>
          <div>{moment(date, DATE_FORMAT_YYYY_MM_DD).format('DD')}</div>
        </div>
      </Col>
      <Col className="roster-shift-details-main">
        {/* <Col className="roster-shift day-shift d-flex align-items-center draftshift"> */}
        {shifts.length > 0 &&
          shifts.map((item: IScheduleItem) => (
            <Col
              className={`roster-shift d-flex align-items-center 
                    ${item.published ? 'publishedshift' : 'draftshift'}
                    ${item.isNoShow ? 'noshow' : ''}                   
                    ${DAY_SHIFTS.includes(item.shortName) || item.isNextDayNightShift ? 'width-shift' : ''} 
                    ${MID_SHIFTS.includes(item.shortName) ? 'width-shift mid-shift' : ''} 
                    ${NIGHT_SHIFTS.includes(item.shortName) && !item.isNextDayNightShift ? 'width-shift night-shift' : ''}`}
            >
              <div className="w-100">
                <div className="shift-name`">{`${item.shiftName} (${item.shortName})`}</div>
                <div className="ca-count d-flex">
                  {Strings.Shift.CA}: {`${item.totalAvailed}/${item.totalCARequired}`}
                  {item.lightDutyRequired > NUMBER_0 ? <span className="ps-2 lateduty">{`${Strings.Shift.LD}: ${item.lightDutyCount}/${item.lightDutyRequired}`}</span> : ''}
                  <div className="colorgroup">
                    {item.yellowRequired > NUMBER_0 && <span className="colorcode yellow" />}
                    {item.blueRequired > NUMBER_0 && <span className="colorcode blue" />}
                    {item.redRequired > NUMBER_0 && <span className="colorcode red" />}
                    {item.whiteRequired > NUMBER_0 && <span className="colorcode white" />}
                  </div>
                </div>
                {!isEmpty(item.shiftFromTime) && !isEmpty(item.shiftToTime) && (
                  <div className="shift-time">
                    {NIGHT_SHIFTS.includes(item.shortName)
                      ? `${moment.utc(item.shiftFromTime).format(DATE_FORMAT_DD_MMM_HHMMA)} - ${moment.utc(item.shiftToTime).format(DATE_FORMAT_DD_MMM_HHMMA)}`
                      : `${moment.utc(item.shiftFromTime).format(DATE_FORMAT_HHMMA)} - ${moment.utc(item.shiftToTime).format(DATE_FORMAT_HHMMA)}`}
                  </div>
                )}
                {item.onCallRequired > 0 && (
                  <span className="ca-count">
                    {Strings.Label.OnCall}: {`${item.onCallTotal}/${item.onCallRequired}`} {item.isOnCall && <OnCall />}
                  </span>
                )}
              </div>
              <Dropdown align="end">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <More />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href={void 0} onClick={() => navigate({ pathname: `/shift-details/${item.shiftId}/${ScheduleTabs.MySchedule}` }, { state: { filterItems } })}>
                    {Strings.Shift.Details}
                  </Dropdown.Item>
                  {!item.published && isCurrentTimeIsBeforeShiftStartTime(item.shiftFromTime || '') && (
                    <Dropdown.Item href={void 0} onClick={() => onButtonClick(item.shiftId || 0, item.shiftFromTime || '', MyScheduleActions.Unassign)}>
                      {Strings.Shift.UnAssign}
                    </Dropdown.Item>
                  )}
                  {item.published && isCurrentTimeIsBeforeShiftStartTime(item.shiftFromTime || '') && (
                    <Dropdown.Item href={void 0} onClick={() => onButtonClick(item.shiftId || 0, item.shiftFromTime || '', MyScheduleActions.Callout)}>
                      {Strings.Shift.CallOut}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {NIGHT_SHIFTS.includes(item.shortName) && (
                <div className={`shift-arrow${item.isNextDayNightShift ? ' next-day' : ''}`}>
                  <DownFill />
                </div>
              )}
            </Col>
          ))}
        {leaves.length > 0 &&
          leaves.map((leave: ILeaveDetails) => (
            <Col
              className={`roster-shift d-flex align-items-center leave
                    ${leave.leaveStatus === LeaveStatus.Approved ? 'publishedshift' : 'draftshift'}`}
            >
              <div className="w-100">
                <div>
                  {leave.leaveStatus === LeaveStatus.Approved ? Strings.Shift.ApprovedLeave : Strings.Shift.RequestedLeave}
                  <span className="d-block">({LeaveTypes[leave.leaveType || 1].replace(/_/g, ' ')})</span>
                </div>
              </div>

              <Dropdown align="end">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <More />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href={void 0} onClick={() => onButtonClick(leave.leaveRequestId || 0, '', MyScheduleActions.LeaveDetails)}>
                    {Strings.Shift.Details}
                  </Dropdown.Item>
                  {leave.leaveStatus === LeaveStatus.Pending_Approval && (
                    <Dropdown.Item href={void 0} onClick={() => onButtonClick(leave.leaveRequestId || 0, '', MyScheduleActions.DeleteLeave)}>
                      {Strings.Shift.DeleteLeave}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          ))}
        {holidays.length > 0 && (
          <Col className="roster-shift d-flex align-items-center holiday">
            <div className="w-100">
              <div className="fw-sb text-center">{Strings.Label.Holiday}</div>
            </div>
          </Col>
        )}
      </Col>
    </Row>
  );
};
export default RosterTable;
