/**
 * @file   src\containers\Manager\TrackLD\TrackLDList.tsx
 * @brief  Track LD Reasons page
 * @date   October, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { IObject } from '../../../interfaces/generalInterface';
import { LD_REASON_SCHEMA } from '../../../validations/manageLdSchema';
import { validateForm } from '../../../utils/validationHelper';
import Loader from '../../../components/Loader';
import { Row, Col, Table, Button, useState, Modal, moment, useEffect } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import PDFIcon from '../../../assests/icons/PDF';
import Sort from '../../../assests/icons/Sort';
import KKIDatepicker from '../../../components/KKIDatepicker';
import Pagination from '../../../components/Paginate';
import KKIInput from '../../../components/KKIInput';
import KKISelect from '../../../components/KKISelect';
import KKICheckbox from '../../../components/KKICheckbox';
import {
  DATE_END,
  DATE_FORMAT_DD_MMM_YYYY,
  DATE_FORMAT_YYYY_MM_DD,
  DATE_START,
  HTTP_STATUS_200,
  INJURY_REASON_LABEL,
  NUMBER_0,
  NUMBER_1,
  NUMBER_10,
  NUMBER_250,
  OTHER_REASON_LABEL,
  PAGE_SIZE,
  TIME_STARTS_FORMAT,
} from '../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { InjurySubTypes, LdReasonsSortField, SortOrderType } from '../../../utils/enums';
import { ILDListRequest, ILDreasonRequest, ILdReasons, ILdusers } from '../../../interfaces/lightdutyInterface';
import { exportLDReasonList, getAllLDUsers, getLdReasons, reportLDreason, revertAllLDState } from '../../../store/actions/lightDutyAction';
import { allowIntegerOnly, stringFormat } from '../../../utils/helper';
import SortDownIcon from '../../../assests/icons/SortDown';
import SortUpIcon from '../../../assests/icons/SortUp';
import { MessageToaster } from '../../../utils/toastUtils';

// Toast object creation.
const toast = new MessageToaster();

// Define item count to be displayed per page.
const pageItemCount: number = PAGE_SIZE;

const TrackLDReason = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const {
    isFetchLDuserLoading,
    ldUsers,
    total,
    isLdReasonsSuccess,
    ldReasonsErrorCode,
    ldReasonsMessage,
    ldReasons,
    isReportLDReasonLoading,
    isReportLDReasonSuccess,
    reportexportLdReasonErrorCode,
    reportLDReasonMessage,
    isExportLdReasonLoading,
    isExportLdReasonSuccess,
    exportLdReasonErrorCode,
    exportLdReasonMessage,
    pdfData,
  } = useAppSelector((state: RootState) => state.lightDuty);

  // Initialize component state variables.
  const [startDateSelected, setStartDateSelected] = useState<Date | null>(moment().toDate());
  const [endDateSelected, setEndDateSelected] = useState<Date | null>(moment().toDate());
  const [pageSize] = useState<number>(pageItemCount);
  const [pageIndex, setPageIndex] = useState<number>(NUMBER_1);
  const [sortBy, setSortBy] = useState<string>(LdReasonsSortField[LdReasonsSortField.name]);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);
  const [show, setShow] = useState(false);
  const [LdReasonList, setLdReasonList] = useState<IObject[]>([]);
  const [reasonId, setReasonId] = useState<IObject | null>(null);
  const [description, setDescription] = useState<string>('');
  const [errorFields, setErrorFields] = useState<IObject | null>(null);
  const [reportingDate, setReportingDate] = useState<Date | null>(moment().toDate());
  const [followUpDate, setFollowUpDate] = useState<Date | null>(moment().toDate());
  const [empId, setEmployeeId] = useState<number | null>(null);
  const [injurySubType, setInjurySubType] = useState<number>(InjurySubTypes.Work_Injury);

  const handleClose = () => setShow(false);

  // Component initial loading.
  useEffect(() => {
    // Component unmount
    return () => {
      dispatch(revertAllLDState());
    };
  }, []);

  // Method to fetch Ld reasons applied users list.
  const fetchLdReasonsList = () => {
    const ldReasonsAppliedUsersListRequest: ILDListRequest = {
      startDate: moment(startDateSelected).format(DATE_FORMAT_YYYY_MM_DD),
      endDate: moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD),
      page: pageIndex,
      sortBy,
      sortOrder: SortOrderType[sortOrder],
    };
    dispatch(getAllLDUsers(ldReasonsAppliedUsersListRequest));
  };

  // Clear ld reasons submission form.
  const clearLdReasonsForm = () => {
    setReasonId(null);
    setInjurySubType(InjurySubTypes.Work_Injury);
    setReportingDate(moment().toDate());
    setFollowUpDate(moment().toDate());
    setEmployeeId(null);
    setDescription('');
    setErrorFields(null);
  };

  // Fetch ld reasons applied users list when the dependent field changes.
  useEffect(() => {
    // Get Ld reason applied Users list.
    fetchLdReasonsList();
  }, [pageIndex, sortBy, sortOrder]);

  // Ld reasons api response state change.
  useEffect(() => {
    if (isLdReasonsSuccess && ldReasonsErrorCode === HTTP_STATUS_200) {
      if (ldReasons && ldReasons.length > 0) {
        const reasonOptions = ldReasons.map((leave: ILdReasons): IObject => ({ label: leave.reason, value: leave.reasonId.toString() }));
        setLdReasonList(reasonOptions);
        setReasonId(reasonOptions[0] ?? null);
      }
    } else if (!isLdReasonsSuccess && ldReasonsErrorCode > HTTP_STATUS_200 && ldReasonsMessage) {
      toast.toastError(ldReasonsMessage);
    }
  }, [isLdReasonsSuccess, ldReasonsErrorCode, ldReasonsMessage, ldReasons]);

  // Report ld reasons api response state side effect handling.
  useEffect(() => {
    if (isReportLDReasonSuccess && reportexportLdReasonErrorCode === HTTP_STATUS_200 && reportLDReasonMessage) {
      toast.toastSuccess(reportLDReasonMessage);
      clearLdReasonsForm();
      handleClose();
      fetchLdReasonsList();
    } else if (!isReportLDReasonSuccess && reportexportLdReasonErrorCode > HTTP_STATUS_200 && reportLDReasonMessage) {
      toast.toastError(reportLDReasonMessage);
    }
  }, [isReportLDReasonSuccess, reportexportLdReasonErrorCode, reportLDReasonMessage]);

  // Export pdf api response state change handling.
  useEffect(() => {
    if (isExportLdReasonSuccess && pdfData) {
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create a link element, set download attributes, and trigger a click
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'LD Reasons List.pdf'); // Set the PDF filename
      document.body.appendChild(link);
      link.click();
      toast.toastSuccess(Strings.Text.LdReasonsExportSuccessText);
      // Cleanup: remove the link and revoke the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
      dispatch(revertAllLDState());
    } else if (!isExportLdReasonSuccess && exportLdReasonErrorCode > HTTP_STATUS_200 && exportLdReasonMessage) {
      toast.toastError(exportLdReasonMessage);
      dispatch(revertAllLDState());
    }
  }, [isExportLdReasonSuccess, exportLdReasonErrorCode, exportLdReasonMessage, pdfData]);

  // Function to handle the report ld reason action.
  const handleReportLdReasons = () => {
    clearLdReasonsForm();
    dispatch(getLdReasons());
    setDescription('');
    setShow(true);
  };

  // Handle the "Export as PDF" button click event.
  const handleExportPDFClick = (): void => {
    if (startDateSelected && endDateSelected) {
      dispatch(
        exportLDReasonList({
          startDate: moment(startDateSelected).format(DATE_FORMAT_YYYY_MM_DD),
          endDate: moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD),
          sortBy,
          sortOrder: SortOrderType[sortOrder],
        }),
      );
    }
  };

  // Start/End date filter input select event handler.
  const handleDateChange = (date: Date | null, type: string) => {
    if (type === DATE_START) {
      setStartDateSelected(date);
      if (endDateSelected && date && date > endDateSelected) {
        setEndDateSelected(date);
      }
    } else {
      setEndDateSelected(date);
    }
  };

  // Sort header change event.
  const handleSort = (field: string) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortBy) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortBy(field);
    setSortOrder(newSortOrder);
  };

  // Get list on button click handler.
  const onClickGo = () => {
    fetchLdReasonsList();
  };

  // Employee ID input change handler.
  const onEmployeeIdChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setEmployeeId(Number(value));
  };

  // Ld reasons reproting/followup date input select event handler.
  const handleLdReasonsDateChange = (date: Date | null, type: string) => {
    if (type === DATE_START) {
      setReportingDate(date);
      if (endDateSelected && date && date > endDateSelected) {
        setFollowUpDate(date);
      }
    } else {
      setFollowUpDate(date);
    }
  };

  // Ld reason type dropdown select change handler.
  const onLdReasonSelect = (val: IObject) => {
    setReasonId(val);
    setDescription('');
    setErrorFields(null);
  };
  // Injury sub type radio button click event handler.
  const onInjurySubTypeChage = (event: any) => {
    const { value } = event.target;
    setInjurySubType(Number(value));
  };

  // Specific ld reason input change handler.
  const onDescriptionChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setDescription(value);
  };

  // Report Ld reasons form submission event.
  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    let fromDate = '';
    let toDate = '';
    if (reportingDate && followUpDate) {
      const fromDateString = moment(new Date(reportingDate)).format(DATE_FORMAT_YYYY_MM_DD);
      fromDate = `${fromDateString}T${TIME_STARTS_FORMAT}.000Z`;
      const toDateString = moment(new Date(followUpDate)).format(DATE_FORMAT_YYYY_MM_DD);
      toDate = `${toDateString}T${TIME_STARTS_FORMAT}.000Z`;
    }
    const ldReasonRequest: ILDreasonRequest = {
      empId,
      reasonId: reasonId?.value ? Number(reasonId.value) : NUMBER_0,
      reasonSubType: reasonId?.label === INJURY_REASON_LABEL ? injurySubType : NUMBER_0,
      reportingDate: fromDate,
      followUpDate: toDate,
      description,
    };
    const errorresult = await validateForm(ldReasonRequest, LD_REASON_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      dispatch(reportLDreason(ldReasonRequest));
    }
  };

  // Function to get the display text for the injury type
  const getInjuryTypeDisplay = (lduser: ILdusers): string => {
    if (lduser.reason === INJURY_REASON_LABEL) {
      switch (lduser.injuryTypeId) {
        case InjurySubTypes.Work_Injury:
          return InjurySubTypes[InjurySubTypes.Work_Injury].split('_')[0];
        case InjurySubTypes.Non_Work_Injury:
          return InjurySubTypes[lduser.injuryTypeId].replace('_', '-').replace(/_[^_]+$/, '');
        default:
          return 'NA';
      }
    }
    return 'NA';
  };

  return (
    <div>
      <div className="pageheader">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h1>{Strings.HD.TrackLDReasons}</h1>
          </Col>
        </Row>
      </div>
      <div className="filter-main mb-3">
        <Row className="d-flex justify-content-between">
          <Col xl={6} md={7} sm={12}>
            <Row className="align-items-center">
              <Col xs className="calendar-mob-left">
                <KKIDatepicker
                  id="kkiStartDate"
                  name="kkiStartDate"
                  placeholder={Strings.Label.StartDate}
                  value={startDateSelected}
                  dateFormat="dd MMM yyyy"
                  // minDate={moment().toDate()}
                  onChangeDatepicker={(date: any) => handleDateChange(date, DATE_START)}
                />
              </Col>
              <Col xs="auto" className="px-0">
                {Strings.Schedule.DateFilterText}
              </Col>
              <Col xs className="calendar-mob-right">
                <KKIDatepicker
                  id="kkiEndDate"
                  name="kkiEndDate"
                  placeholder={Strings.Label.EndDate}
                  value={endDateSelected}
                  dateFormat="dd MMM yyyy"
                  minDate={startDateSelected || moment().toDate()}
                  onChangeDatepicker={(date: any) => handleDateChange(date, DATE_END)}
                />
              </Col>
              <Col md="auto" className="ps-0">
                <Button variant="primary" onClick={onClickGo}>
                  {Strings.Button.Go}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md="auto">
            <Button variant="primary" className="me-2" onClick={handleReportLdReasons}>
              {Strings.Button.ReportLDReason}
            </Button>
            <Button variant="outline-primary" type="submit" onClick={handleExportPDFClick}>
              <PDFIcon />
              {Strings.Button.ExportasPDF}
            </Button>
          </Col>
        </Row>
      </div>

      <div className="table-border">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th onClick={() => handleSort(LdReasonsSortField[LdReasonsSortField.name])} style={{ cursor: 'pointer' }}>
                {Strings.Label.Name}
                <span className="ms-1">
                  {sortBy !== LdReasonsSortField[LdReasonsSortField.name] && <Sort />}
                  {sortBy === LdReasonsSortField[LdReasonsSortField.name] && sortOrder === SortOrderType.DESC && <SortDownIcon />}
                  {sortBy === LdReasonsSortField[LdReasonsSortField.name] && sortOrder === SortOrderType.ASC && <SortUpIcon />}
                </span>
              </th>
              <th>{Strings.Label.EmpId}</th>
              <th>{Strings.Label.Reason}</th>
              <th>{Strings.Label.InjuryType}</th>
              <th onClick={() => handleSort(LdReasonsSortField[LdReasonsSortField.reportedDate])} style={{ cursor: 'pointer' }}>
                {Strings.Label.ReportedDate}
                <span className="ms-1">
                  {sortBy !== LdReasonsSortField[LdReasonsSortField.reportedDate] && <Sort />}
                  {sortBy === LdReasonsSortField[LdReasonsSortField.reportedDate] && sortOrder === SortOrderType.DESC && <SortDownIcon />}
                  {sortBy === LdReasonsSortField[LdReasonsSortField.reportedDate] && sortOrder === SortOrderType.ASC && <SortUpIcon />}
                </span>
              </th>
              <th>{Strings.Label.FollowUpDate}</th>
            </tr>
          </thead>
          <tbody>
            {ldUsers &&
              ldUsers.length > NUMBER_0 &&
              ldUsers.map((lduser: ILdusers) => (
                <tr key={`${Date.now()}-${Math.random()}`}>
                  <td>{lduser.name}</td>
                  <td>{lduser.empId}</td>
                  <td>{lduser.reason === OTHER_REASON_LABEL ? `${lduser.reason} - ${lduser.description}` : lduser.reason}</td>
                  <td>{getInjuryTypeDisplay(lduser)}</td>
                  <td>{moment(lduser.reportedDate).format(DATE_FORMAT_DD_MMM_YYYY)}</td>
                  <td>{moment(lduser.followUpDate).format(DATE_FORMAT_DD_MMM_YYYY)}</td>
                </tr>
              ))}

            {!ldUsers ||
              (ldUsers && ldUsers.length === NUMBER_0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    {Strings.Text.NoDataFound}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <span>
          {ldUsers && ldUsers.length > NUMBER_0 && total > 0
            ? stringFormat(
                Strings.Text.TrackLdReasonsPaginationText,
                ldUsers.length > NUMBER_0 ? ((total === NUMBER_1 ? NUMBER_1 : pageIndex) - NUMBER_1) * pageSize + NUMBER_1 : NUMBER_0,
                ((total === NUMBER_1 ? NUMBER_1 : pageIndex) - NUMBER_1) * pageSize + ldUsers.length,
                total,
              )
            : ''}
        </span>
        {ldUsers && ldUsers.length > NUMBER_0 && total > pageSize && (
          <Pagination totalCount={total} pageLimit={pageSize} setCurrentPage={(page: number) => setPageIndex(page)} currentPage={pageIndex - NUMBER_1} prevPage={-1} />
        )}
      </div>
      <Modal show={show} onHide={handleClose} centered size="lg" className="max-680">
        <Modal.Header closeButton>
          <Modal.Title>Report Light Duty Reason</Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmit}>
          <Modal.Body>
            <Row>
              <Col xl={6} className="mb-3">
                <KKIInput
                  id="EmployeeID"
                  label="Employee ID"
                  name="EmployeeID"
                  type="text"
                  value={empId}
                  onChange={onEmployeeIdChange}
                  alert={errorFields?.empId || ''}
                  maxLength={NUMBER_10}
                  onKeyDown={allowIntegerOnly}
                />
              </Col>
              <Col xl={6} className="mb-3">
                <KKISelect
                  id="shift"
                  label={Strings.Label.Reason}
                  name="shift"
                  className="custom-select"
                  placeholder={Strings.Label.Reason}
                  searchvalue={false}
                  options={LdReasonList}
                  value={reasonId}
                  onSelectChange={onLdReasonSelect}
                  alert={errorFields?.reasonId || ''}
                />
              </Col>

              <Col xl={12}>
                {reasonId?.label === INJURY_REASON_LABEL && (
                  <div className="d-flex gap-4 mt-2 mb-3">
                    <KKICheckbox
                      id="oneTimeShift"
                      name="createdShiftType"
                      checkBoxType="radio"
                      label={Strings.Label.WorkInjury}
                      value={InjurySubTypes.Work_Injury}
                      checked={injurySubType === InjurySubTypes.Work_Injury}
                      onChange={onInjurySubTypeChage}
                    />
                    <KKICheckbox
                      id="recurringShift"
                      name="createdShiftType"
                      checkBoxType="radio"
                      label={Strings.Label.NonWorkInjury}
                      value={InjurySubTypes.Non_Work_Injury}
                      checked={injurySubType === InjurySubTypes.Non_Work_Injury}
                      onChange={onInjurySubTypeChage}
                    />
                  </div>
                )}
              </Col>
              <Col xl={6} className="mb-3">
                <KKIDatepicker
                  id="shiftStartDate"
                  name="shiftStartDate"
                  placeholder={Strings.Label.ReportingDate}
                  label={Strings.Label.ReportingDate}
                  value={reportingDate}
                  dateFormat="dd MMM yyyy"
                  minDate={moment().toDate()}
                  onChangeDatepicker={(date: any) => handleLdReasonsDateChange(date, DATE_START)}
                  alert={errorFields?.reportingDate || ''}
                />
              </Col>
              <Col xl={6} className="mb-3">
                <KKIDatepicker
                  id="shiftStartDate"
                  name="shiftStartDate"
                  placeholder={Strings.Label.FollowUpDate}
                  label={Strings.Label.FollowUpDate}
                  value={followUpDate}
                  dateFormat="dd MMM yyyy"
                  minDate={reportingDate || moment().toDate()}
                  onChangeDatepicker={(date: any) => handleLdReasonsDateChange(date, DATE_END)}
                  alert={errorFields?.followUpDate || ''}
                />
              </Col>
              {reasonId?.label === OTHER_REASON_LABEL && (
                <Col xl={12}>
                  <KKIInput
                    id="Reason"
                    label="Specify Reason"
                    name="yellowCARequired"
                    type="text"
                    as="textarea"
                    rows={3}
                    value={description}
                    onChange={onDescriptionChange}
                    alert={errorFields?.description || ''}
                    maxLength={NUMBER_250}
                  />
                </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            <Button variant="outline-primary" onClick={handleClose}>
              {Strings.Button.Cancel}
            </Button>
            <Button variant="primary" type="submit">
              {Strings.Button.Submit}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {(isFetchLDuserLoading || isReportLDReasonLoading || isExportLdReasonLoading) && <Loader />}
    </div>
  );
};
export default TrackLDReason;
