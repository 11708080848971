/* eslint-disable prefer-destructuring */
/**
 * @file   src\containers\CA\Schedule\MyRoster.tsx
 * @brief  This file is responsible for managing and viewing ols and current shifts for CA.
 * @date   Jul, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, Row, Button, ButtonGroup, moment, useState, useEffect, Modal, useLocation } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import KKIDatepicker from '../../../components/KKIDatepicker';
import Arrow from '../../../assests/icons/DownArrow';
import OnCall from '../../../assests/icons/OnCall';
import { getCalculatedDateTime, getDatesBetween } from '../../../utils/helper';
import { DateTimeCalculatedTypes, MyScheduleActions, OperationTypes, PrevNextTypes, ShiftStatus } from '../../../utils/enums';
import { CURRENT_DATE_FORMAT_WITH_OFFSET, DATE_FORMAT_YYYY_MM_DD, HTTP_STATUS_200, NIGHT_SHIFTS, NUMBER_0, NUMBER_1, NUMBER_6, NUMBER_7 } from '../../../utils/constants';
import ScheduleShiftTypes from '../../../components/commonComponents/ShiftTypes';
import RosterTable from '../../../components/RosterTable';
import {
  ICallOutShiftRequest,
  ICAScheduleItems,
  IGetCAShiftsAndLeaveDetailsRequest,
  IRosterScheduleView,
  IScheduleItem,
  IUnassignCARequest,
} from '../../../interfaces/scheduleInterface';
import { ILeaveDetails } from '../../../interfaces/leaveInterface';
import LeaveDetails from '../../../components/LeaveDetailsCard';
import { RootState } from '../../../store';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { callOutShift, getAllShiftTypes, revertAllScheduleState, unassignCA } from '../../../store/actions/manageCAScheduleAction';
import { MessageToaster } from '../../../utils/toastUtils';
import { getSchedulesAndLeaveDetails } from '../../../store/actions/commonAction';
import { deleteLeaveReuest, revertAllLeaveState } from '../../../store/actions/manageLeaveAction';
import Loader from '../../../components/Loader';

// Toast object creation.
const toast = new MessageToaster();

const MyRoaster = () => {
  // Create current location object.
  const location = useLocation();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access location state variables.
  const filterItemsData = location.state?.filterItems || null;

  // Access redux state variables.
  const {
    isShiftTypesSuccess,
    shiftTypesErrorCode,
    shiftTypesMessage,
    shiftTypes,
    isCallOutShiftLoading,
    isCallOutShiftSuccess,
    callOutShiftErrorCode,
    callOutShiftMessage,
    isUnassignShiftLoading,
    isUnassignShiftSuccess,
    unassignShiftErrorCode,
    unassignShiftMessage,
  } = useAppSelector((state: RootState) => state.manageCASchedule);
  const { isDeleteLeaveRequestLoading, isDeleteLeaveRequestSuccess, deleteLeaveRequestErrorCode, deleteLeaveRequestMessage } = useAppSelector(
    (state: RootState) => state.manageLeave,
  );
  const {
    isUserScheduleAndLeaveDetailsLoading,
    isUserScheduleAndLeaveDetailsSuccess,
    userScheduleAndLeaveDetails,
    userScheduleAndLeaveDetailsErrorCode,
    userScheduleAndLeaveDetailsMessage,
  } = useAppSelector((state: RootState) => state.common);

  // Initialize component state variables.
  const [startDateSelected, setStartDateSelected] = useState<Date | null>(filterItemsData ? filterItemsData.startDateSelected : moment().toDate());
  const [endDateSelected, setEndDateSelected] = useState<Date | null>(
    filterItemsData ? filterItemsData.endDateSelected : getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Add, NUMBER_6, moment().toDate()),
  );
  const [selectedShiftTypeIds, setSelectedShiftTypeIds] = useState<number[]>(filterItemsData ? filterItemsData.selectedShiftTypeIds : []);
  const [selectedPublishStatus, setSelectedPublishStatus] = useState<number[]>(
    filterItemsData ? filterItemsData.selectedPublishStatus : [ShiftStatus.Published, ShiftStatus.Draft],
  );
  const [scheduleList, setScheduleList] = useState<any>(null);
  const [leaveDetailsPopup, showLeaveDetailsPopup] = useState<boolean>(false);
  const [unAssignPopup, showUnAssignPopup] = useState<boolean>(false);
  const [callOutPopup, showCallOutPopup] = useState<boolean>(false);
  const [deleteLeavePopup, showDeleteLeavePopup] = useState<boolean>(false);
  const [caLeaveDetails, setCALeaveDetails] = useState<ILeaveDetails | null>(null);
  const [scheduleDates, setScheduleDates] = useState<string[]>(
    getDatesBetween(moment(startDateSelected).format(DATE_FORMAT_YYYY_MM_DD), moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD)),
  );
  const [leaveStatus, setLeaveStatus] = useState<boolean>(filterItemsData?.leaveStatus !== false);
  const [unAssignRequest, setUnAssignRequest] = useState<IUnassignCARequest | null>(null);
  const [callOutShiftRequest, setCallOutShiftRequest] = useState<ICallOutShiftRequest | null>(null);
  const [leaveRequestId, setLeaveRequestId] = useState<number>(NUMBER_0);
  const [filterItems, setFilterItems] = useState<any>(null);

  //  Dispatch an action to simulate shift type data fetching
  useEffect(() => {
    dispatch(getAllShiftTypes());
    return () => {
      dispatch(revertAllScheduleState());
      dispatch(revertAllLeaveState());
    };
  }, []);

  // Method to fetch schedules api.
  const fetchSchedules = () => {
    const scheduleRequest: IGetCAShiftsAndLeaveDetailsRequest = {
      startDate: moment(startDateSelected).subtract(NUMBER_1, 'days').format(DATE_FORMAT_YYYY_MM_DD),
      endDate: moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD),
      published: selectedPublishStatus,
      shiftType: selectedShiftTypeIds,
      includeLeaves: leaveStatus === true ? NUMBER_1 : NUMBER_0,
    };
    setFilterItems((prev: any) => ({
      ...prev,
      startDateSelected,
      endDateSelected,
      selectedShiftTypeIds,
      selectedPublishStatus,
      leaveStatus,
    }));
    dispatch(getSchedulesAndLeaveDetails(scheduleRequest));
  };

  // State change side effect handler to fetch schedule api.
  useEffect(() => {
    fetchSchedules();
  }, [startDateSelected, selectedPublishStatus, selectedShiftTypeIds, leaveStatus]);

  // Shift type response state change.
  useEffect(() => {
    if (isShiftTypesSuccess && shiftTypesErrorCode === HTTP_STATUS_200 && shiftTypes) {
      const shiftIds = shiftTypes.map((shift) => shift.shiftId);
      if (!filterItemsData?.selectedShiftTypeIds || (filterItemsData?.selectedShiftTypeIds && filterItemsData?.selectedShiftTypeIds.length === NUMBER_0)) {
        setSelectedShiftTypeIds(shiftIds);
      }
    } else if (!isShiftTypesSuccess && shiftTypesErrorCode > HTTP_STATUS_200 && shiftTypesMessage) {
      toast.toastError(shiftTypesMessage);
    }
  }, [isShiftTypesSuccess, shiftTypesErrorCode, shiftTypes, shiftTypesMessage]);

  // Function to format the schedule data to a bindable cell format.
  const formatScheduleData = () => {
    const holidays = userScheduleAndLeaveDetails && userScheduleAndLeaveDetails?.holidays?.map((day: string) => moment(day).format(DATE_FORMAT_YYYY_MM_DD));
    const scheduleCAData = userScheduleAndLeaveDetails?.cAList || [];
    let newSchedule: any = {};
    const groupedSchedule = scheduleCAData.map((schItem: ICAScheduleItems) => {
      newSchedule = schItem.scheduleInfo?.reduce((acc: any, item: IScheduleItem) => {
        let date: string | null = null;

        if (item.shiftFromTime) {
          date = moment.utc(item.shiftFromTime).format(DATE_FORMAT_YYYY_MM_DD);
        }

        if (date) {
          if (!acc[date]) {
            acc[date] = {
              date,
              shifts: [],
              leaves: [],
              holidays: [],
            };
          }

          if (item.shiftFromTime) {
            const itemCopy = { ...item };
            acc[date].shifts.push(item);
            const nextDate = new Date(new Date(date).getTime() + 86400000).toISOString().split('T')[0];
            if (NIGHT_SHIFTS.includes(item.shortName) && scheduleDates.includes(nextDate)) {
              if (!acc[nextDate]) {
                acc[nextDate] = {
                  date: nextDate,
                  shifts: [],
                  leaves: [],
                  holidays: [],
                };
              }
              itemCopy.isNextDayNightShift = true;
              acc[nextDate].shifts.push(itemCopy);
            }
          }
        }

        return acc;
      }, {});

      // Loop through leave details to add leaves to the specific date key.
      schItem.leaveInfo?.forEach((leave: ILeaveDetails) => {
        // Get all leave days from the leave from and to date.
        const leaveDays = getDatesBetween(moment(leave.leaveFrom).format(DATE_FORMAT_YYYY_MM_DD), moment(leave.leaveTo).format(DATE_FORMAT_YYYY_MM_DD));
        // Loop through leave days and add leave details object.
        newSchedule = newSchedule ?? {};
        leaveDays.forEach((leaveDate: string) => {
          if (!newSchedule[leaveDate] && scheduleDates.includes(leaveDate)) {
            newSchedule[leaveDate] = {
              date: leaveDate,
              shifts: [],
              leaves: [leave],
              holidays: [],
            };
          } else {
            newSchedule[leaveDate]?.leaves.push(leave);
          }
        });
      });

      let sortedSchedules: IRosterScheduleView | null = null;
      // Add dates objects not in the schedule list.
      scheduleDates.forEach((date: string) => {
        // If the date is not already a key in schedules, add it with a null value
        if (newSchedule && Object.keys(newSchedule).length > 0) {
          if (!Object.prototype.hasOwnProperty.call(newSchedule, date)) {
            if (holidays && holidays.length > 0 && holidays.includes(date)) {
              newSchedule[date] = {
                date,
                shifts: [],
                leaves: [],
                holidays: ['holiday'],
              };
            } else {
              newSchedule[date] = {
                date,
                shifts: [],
                leaves: [],
                holidays: [],
              };
            }
          }
        } else {
          newSchedule = {};
          newSchedule[date] = {
            date,
            shifts: [],
            leaves: [],
            holidays: [],
          };
        }
      });

      // Sort the schedules by date
      sortedSchedules = Object.keys(newSchedule)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .reduce((ar, date) => {
          (ar as any)[date] = newSchedule[date];
          return ar;
        }, {});

      const previousDate = moment(startDateSelected).subtract(NUMBER_1, 'days').format(DATE_FORMAT_YYYY_MM_DD);
      if (sortedSchedules[previousDate]) {
        delete sortedSchedules[previousDate];
      }
      return {
        caId: schItem.caId,
        firstName: schItem.firstName,
        lastName: schItem.lastName,
        empId: schItem.empId,
        assignedHours: schItem.assignedHours,
        schedules: sortedSchedules,
      };
    });
    setScheduleList(groupedSchedule);
  };

  // Fetch schedules and leaves api response state side effect handling.
  useEffect(() => {
    if (isUserScheduleAndLeaveDetailsSuccess && userScheduleAndLeaveDetailsErrorCode === HTTP_STATUS_200 && userScheduleAndLeaveDetailsMessage && userScheduleAndLeaveDetails) {
      formatScheduleData();
    } else if (!isUserScheduleAndLeaveDetailsSuccess && userScheduleAndLeaveDetailsErrorCode > HTTP_STATUS_200 && userScheduleAndLeaveDetailsMessage) {
      toast.toastError(userScheduleAndLeaveDetailsMessage);
    }
  }, [isUserScheduleAndLeaveDetailsSuccess, userScheduleAndLeaveDetailsErrorCode, userScheduleAndLeaveDetailsMessage, userScheduleAndLeaveDetails]);

  // Unassign shift api response state change.
  useEffect(() => {
    if (isUnassignShiftSuccess && unassignShiftErrorCode === HTTP_STATUS_200 && unassignShiftMessage) {
      toast.toastSuccess(unassignShiftMessage);
      dispatch(revertAllScheduleState());
      fetchSchedules();
    } else if (!isUnassignShiftSuccess && unassignShiftErrorCode > HTTP_STATUS_200 && unassignShiftMessage) {
      toast.toastError(unassignShiftMessage);
      dispatch(revertAllScheduleState());
    }
  }, [isUnassignShiftSuccess, unassignShiftErrorCode, unassignShiftMessage]);

  // Callout shift api response state change.
  useEffect(() => {
    if (isCallOutShiftSuccess && callOutShiftErrorCode === HTTP_STATUS_200 && callOutShiftMessage) {
      toast.toastSuccess(callOutShiftMessage);
      dispatch(revertAllScheduleState());
      fetchSchedules();
    } else if (!isCallOutShiftSuccess && callOutShiftErrorCode > HTTP_STATUS_200 && callOutShiftMessage) {
      toast.toastError(callOutShiftMessage);
      dispatch(revertAllScheduleState());
    }
  }, [isCallOutShiftSuccess, callOutShiftErrorCode, callOutShiftMessage]);

  // Delete leave api response state change.
  useEffect(() => {
    if (isDeleteLeaveRequestSuccess && deleteLeaveRequestErrorCode === HTTP_STATUS_200 && deleteLeaveRequestMessage) {
      toast.toastSuccess(deleteLeaveRequestMessage);
      dispatch(revertAllScheduleState());
      fetchSchedules();
    } else if (!isDeleteLeaveRequestSuccess && deleteLeaveRequestErrorCode > HTTP_STATUS_200 && deleteLeaveRequestMessage) {
      toast.toastError(deleteLeaveRequestMessage);
      dispatch(revertAllScheduleState());
    }
  }, [isDeleteLeaveRequestSuccess, deleteLeaveRequestErrorCode, deleteLeaveRequestMessage]);

  // Start date filter input select event handler.
  const handleStartDateFilterChange = (date: Date | null) => {
    if (date !== null) {
      const endDt = getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Add, NUMBER_6, moment(date).toDate());
      setScheduleDates(getDatesBetween(moment(date).format(DATE_FORMAT_YYYY_MM_DD), moment(endDt).format(DATE_FORMAT_YYYY_MM_DD)));
      setStartDateSelected(date);
      setEndDateSelected(endDt);
    }
  };

  // Previous, Next button click handler.
  const togglePreviousNextButton = (type: number) => {
    if (type === PrevNextTypes.Prev) {
      const startDt = getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Subtract, NUMBER_7, startDateSelected || moment().toDate());
      const endDt = getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Subtract, NUMBER_7, endDateSelected || moment().toDate());
      setScheduleDates(getDatesBetween(moment(startDt).format(DATE_FORMAT_YYYY_MM_DD), moment(endDt).format(DATE_FORMAT_YYYY_MM_DD)));
      setStartDateSelected(startDt);
      setEndDateSelected(endDt);
    }
    if (type === PrevNextTypes.Next) {
      const startDt = getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Add, NUMBER_7, startDateSelected || moment().toDate());
      const endDt = getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Add, NUMBER_7, endDateSelected || moment().toDate());
      setScheduleDates(getDatesBetween(moment(startDt).format(DATE_FORMAT_YYYY_MM_DD), moment(endDt).format(DATE_FORMAT_YYYY_MM_DD)));
      setStartDateSelected(startDt);
      setEndDateSelected(endDt);
    }
  };

  // Filter apply button event handler.
  const handleApply = (filters: { shiftTypeIds: number[]; publishStatus: number[]; isLeaveSelected: boolean }) => {
    setSelectedShiftTypeIds(filters.shiftTypeIds);
    setSelectedPublishStatus(filters.publishStatus);
    setLeaveStatus(filters.isLeaveSelected);
  };

  // Method to show leave details.
  const manageLeaveDetails = (caId: number, id: number) => {
    const caInfo = userScheduleAndLeaveDetails && userScheduleAndLeaveDetails.cAList.find((ca: ICAScheduleItems) => ca.caId === caId);
    const leaveInfo = caInfo?.leaveInfo.find((item: ILeaveDetails) => item.leaveRequestId === id);
    setCALeaveDetails(leaveInfo || null);
    showLeaveDetailsPopup(true);
  };

  // Method to un-assign shift.
  const unAssignShift = (shift: number, caId: number, shiftStart: string) => {
    setUnAssignRequest((prev) => ({
      ...prev,
      caId,
      shift,
      shiftStart,
    }));
    showUnAssignPopup(true);
  };

  // Method to call-out shift.
  const callOutCAFromShift = (shift: number, shiftStart: string) => {
    setCallOutShiftRequest((prev) => ({
      ...prev,
      shiftId: shift,
      shiftStart,
      currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
    }));
    showCallOutPopup(true);
  };

  // Method to delete leave request.
  const deleteLeaveRequest = (id: number) => {
    setLeaveRequestId(id);
    showDeleteLeavePopup(true);
  };

  // My schedule button click handler.
  const onScheduleButtonClick = (id: number, type: number, shiftDate: string, caId: number) => {
    switch (type) {
      case MyScheduleActions.LeaveDetails:
        manageLeaveDetails(caId, id);
        break;
      case MyScheduleActions.Unassign:
        unAssignShift(id, caId, shiftDate);
        break;
      case MyScheduleActions.Callout:
        callOutCAFromShift(id, shiftDate);
        break;
      case MyScheduleActions.DeleteLeave:
        deleteLeaveRequest(id);
        break;
      default:
        break;
    }
  };

  // Unassign button click handler.
  const onUnAssignClick = () => {
    if (unAssignRequest) {
      dispatch(unassignCA(unAssignRequest));
    }
    showUnAssignPopup(false);
  };

  // Callout button click handler.
  const onShiftCallOutClick = () => {
    if (callOutShiftRequest) {
      dispatch(callOutShift(callOutShiftRequest));
    }
    showCallOutPopup(false);
  };

  // Delete leave request button click handler.
  const onDeleteLeaveClick = () => {
    if (leaveRequestId > 0) {
      dispatch(deleteLeaveReuest({ leaveRequestId }));
    }
    showDeleteLeavePopup(false);
  };

  return (
    <div>
      <Row className="justify-content-between pb-1">
        <Col>
          <h6>{Strings.Schedule.SubHeader}</h6>
        </Col>
        <Col xs="auto">
          {Strings.Schedule.AssignedHoursLabel}: <span className="fw-sb">{`${(scheduleList && parseFloat(scheduleList[0]?.assignedHours).toFixed(2)) || 0} Hrs`}</span>
        </Col>
      </Row>
      <div className="filter-main row-filter">
        <Row className="d-flex justify-content-between">
          <Col xl={6} md={7} sm={12}>
            <Row className="align-items-center">
              <Col xs className="calendar-mob-left">
                <KKIDatepicker
                  id="kkiStartDate"
                  name="kkiStartDate"
                  placeholder="placeholder"
                  value={startDateSelected}
                  dateFormat="dd MMM yyyy"
                  onChangeDatepicker={(date: any) => handleStartDateFilterChange(date)}
                />
              </Col>
              <Col xs="auto" className="px-0">
                {Strings.Schedule.DateFilterText}
              </Col>
              <Col xs className="calendar-mob-right">
                <KKIDatepicker id="kkiEndDate" name="kkiEndDate" placeholder="placeholder" value={endDateSelected} dateFormat="dd MMM yyyy" disabled />
              </Col>
              <Col xs="auto" className="ps-0">
                <ButtonGroup aria-label="week change" className="weekchange">
                  <Button variant="secondary" onClick={() => togglePreviousNextButton(PrevNextTypes.Prev)}>
                    <Arrow />
                  </Button>
                  <Button variant="secondary" onClick={() => togglePreviousNextButton(PrevNextTypes.Next)}>
                    <Arrow />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="py-2">
        <Row className="align-items-center">
          <Col className="schedule-legend">
            <span className="draft">{Strings.Filter.Draft}</span>
            <span className="published">{Strings.Filter.Published}</span>
            <span className="oncall">
              <OnCall /> {Strings.Label.OnCall}
            </span>
            <span className="legent4 d-inline-block">{Strings.Filter.Legent4}</span>
          </Col>
          <Col className="filter-sort" xs="auto">
            <Col xs="auto" className="btn-container d-flex">
              <ScheduleShiftTypes
                selectedShiftTypeIds={selectedShiftTypeIds}
                selectedShiftStatus={selectedPublishStatus}
                leaveStatus={leaveStatus}
                onChange={handleApply}
                shiftTypes={shiftTypes}
                isRosterView
              />
            </Col>
          </Col>
        </Row>
      </div>
      <div className="roster-tab-main">
        {scheduleList &&
          scheduleList[0]?.schedules &&
          Object.keys(scheduleList[0].schedules).length > 0 &&
          Object.keys(scheduleList[0].schedules).map((dt: string) => (
            <RosterTable
              key={dt}
              date={dt}
              shifts={scheduleList[0].schedules[dt]?.shifts || []}
              leaves={scheduleList[0].schedules[dt]?.leaves || []}
              holidays={scheduleList[0].schedules[dt]?.holidays || []}
              onButtonClick={(id: number, shiftDate: string, type: number) => onScheduleButtonClick(id, type, shiftDate, scheduleList[0]?.caId || 0)}
              filterItems={filterItems}
            />
          ))}
      </div>
      <Modal show={unAssignPopup} onHide={() => showUnAssignPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.UnAssignPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showUnAssignPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onUnAssignClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={callOutPopup} onHide={() => showCallOutPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.CallOutPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showCallOutPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onShiftCallOutClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={deleteLeavePopup} onHide={() => showDeleteLeavePopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.DeleteLeavePopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showDeleteLeavePopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onDeleteLeaveClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={leaveDetailsPopup} onHide={() => showLeaveDetailsPopup(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Leave.LeaveDetails}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LeaveDetails data={caLeaveDetails} />
        </Modal.Body>
      </Modal>
      {(isCallOutShiftLoading || isUnassignShiftLoading || isDeleteLeaveRequestLoading || isUserScheduleAndLeaveDetailsLoading) && <Loader />}
    </div>
  );
};

export default MyRoaster;
