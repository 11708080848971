/**
 * @file   src\components\ShiftCard.tsx
 * @brief  Component for listing schedule.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Accordion, Card, Col, Row, useAccordionButton, Button, moment, useNavigate } from './ThirdPartyComponents';
import { CustomToggleProps, IShiftCard } from '../interfaces/generalInterface';
import Arrow from '../assests/icons/DownArrow';
import Published from '../assests/icons/Publish';
import Draft from '../assests/icons/Draft';
import DayShift from '../assests/icons/DayShift';
import NightShift from '../assests/icons/NightShift';
import MidShift from '../assests/icons/MidShift';
import { CARoutePath, AdminRoutePath, RoleTypes } from '../utils/enums';
import {
  DATE_FORMAT_DD_MMM_YYYY_HHMMA,
  DATE_FORMAT_HHMMA,
  DATE_FORMAT_DD_MMM_YYYY,
  NUMBER_1,
  DAY_SHIFTS,
  NIGHT_SHIFTS,
  MID_SHIFTS,
  NUMBER_0,
  STORAGE_USER,
} from '../utils/constants';
import useAccordionCollapse from '../hooks/useAccordionCollapse';
import KKICheckbox from './KKICheckbox';
import { getDataFromStorage, isCurrentTimeIsBeforeShiftStartTime } from '../utils/helper';

const CustomToggle = ({ children, eventKey, callback }: CustomToggleProps) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback(eventKey);
  });

  return (
    <button type="button" className="accordion-btn" onClick={decoratedOnClick}>
      {children}
    </button>
  );
};
const ShiftCard = ({ shiftDetails, scheduler, checked, filterItems, onCheckboxChange, onAssignShift }: IShiftCard) => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Get the collapsed state using the custom hook
  const { isCollapsed, toggleCollapse } = useAccordionCollapse();

  // Access userInfo from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);
  // Loggined user role
  const roleId = userInfo?.roleId;

  // Handle checkbox change event and notify the parent component
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onCheckboxChange) {
      onCheckboxChange(e.target.checked, shiftDetails?.shiftId);
    }
  };

  // Determine what to show for color group based on required values
  const renderOpenSlots = () => {
    const { yellowRequired, blueRequired, redRequired, yellowCount, blueCount, redCount, whiteRequired, whiteCount } = shiftDetails || {};

    // Check if all required values are zero
    if (yellowRequired === NUMBER_0 && blueRequired === NUMBER_0 && redRequired === NUMBER_0) {
      return <span>None</span>;
    }

    return (
      <>
        {yellowRequired !== NUMBER_0 && (
          <span className="openslot yellow">
            {yellowCount || NUMBER_0}/{yellowRequired || NUMBER_0}
          </span>
        )}
        {blueRequired !== NUMBER_0 && (
          <span className="openslot blue">
            {blueCount || NUMBER_0}/{blueRequired || NUMBER_0}
          </span>
        )}
        {redRequired !== NUMBER_0 && (
          <span className="openslot red">
            {redCount || NUMBER_0}/{redRequired || NUMBER_0}
          </span>
        )}
        {whiteRequired !== NUMBER_0 && (
          <span className="openslot white">
            {whiteCount || NUMBER_0}/{whiteRequired || NUMBER_0}
          </span>
        )}
      </>
    );
  };

  // Handle navigation to the shift details page.
  const handleShiftDetailsClick = () => {
    const shiftId = shiftDetails?.shiftId.toString();
    const path = scheduler
      ? AdminRoutePath.SCHShiftDetails.replace(':shiftId', shiftId).replace(':type', NUMBER_1.toString())
      : CARoutePath.ShiftDetails.replace(':shiftId', shiftId).replace(':type', NUMBER_1.toString());
    navigate({ pathname: path }, { state: { filterItems } });
  };

  //  Handle navigation to the swap request page if the shift is published.
  const handleSwapRequestClick = () => {
    if (shiftDetails?.published) {
      navigate(`/swap-request/${shiftDetails?.shiftId}`, { state: { from: NUMBER_1 } });
    }
  };

  // Render the assign shift and on-call buttons based on the shift's publish status.
  // If the shift is not published, show "Assign Shift" and "Assign On-Call" buttons.
  // If the shift is published, show the "Swap" button.
  const renderAssignShiftButton = () => {
    if (!shiftDetails?.published) {
      return (
        <>
          <Button
            variant="primary"
            onClick={() => {
              onAssignShift?.(shiftDetails?.shiftId, shiftDetails.startDate, false);
            }}
          >
            {Strings.Button.AssignShift}
          </Button>
          {(shiftDetails?.onCallRequired ?? NUMBER_0) > NUMBER_0 && (
            <Button
              variant="primary"
              onClick={() => {
                onAssignShift?.(shiftDetails?.shiftId, shiftDetails.startDate, true);
              }}
            >
              {Strings.Button.AssignOnCall}
            </Button>
          )}
        </>
      );
    }
    return (
      <Button variant="primary" onClick={handleSwapRequestClick}>
        {Strings.Button.Swap}
      </Button>
    );
  };

  return (
    <Accordion activeKey={!isCollapsed ? '0' : null} className="shiftcard-main">
      <Card>
        <Card.Header className={`d-flex justify-content-between align-items-center ${isCollapsed ? 'collapse' : ''}`}>
          <Col className="d-flex align-items-center">
            {roleId === RoleTypes.Scheduler ? (
              <div className="me-2 position-relative z-1">
                <KKICheckbox id="1a" name="kkicheckbox" checkBoxType="checkbox" checked={checked} onChange={handleCheckboxChange} disabled={shiftDetails?.published} />
              </div>
            ) : (
              <>
                {DAY_SHIFTS.includes(shiftDetails.shortName) ? <DayShift /> : ''}
                {NIGHT_SHIFTS.includes(shiftDetails.shortName) ? <NightShift /> : ''}
                {MID_SHIFTS.includes(shiftDetails.shortName) ? <MidShift /> : ''}
              </>
            )}
            {`${shiftDetails?.shiftName}${shiftDetails?.shortName ? ` (${shiftDetails.shortName})` : ''}`}
            <div>
              <label htmlFor="shift date and time" className="shift-date-mobile">
                {NIGHT_SHIFTS.includes(shiftDetails.shortName)
                  ? `${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_DD_MMM_YYYY)} - ${moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_DD_MMM_YYYY)}`
                  : `${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_DD_MMM_YYYY)}`}
              </label>
            </div>
          </Col>
          <label htmlFor="shift dateand time" className="shift-date-web">
            {NIGHT_SHIFTS.includes(shiftDetails.shortName)
              ? `${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_DD_MMM_YYYY_HHMMA)} - ${moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_DD_MMM_YYYY_HHMMA)}`
              : `${moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_DD_MMM_YYYY)}`}
            {!NIGHT_SHIFTS.includes(shiftDetails?.shortName) ? (
              <span className="shift-time-web">
                {moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_HHMMA)} - {moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_HHMMA)}
              </span>
            ) : (
              ''
            )}
          </label>
          <CustomToggle eventKey="0" callback={toggleCollapse}>
            <Arrow />
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="shift-details">
            <Col className="shift-details-sub">
              <Row className="justify-content-between">
                <Col xs={7} lg={6} xl={6} xxl className="shift-time-mob">
                  {Strings.Schedule.Time}
                  <span>
                    {moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_HHMMA)} - {moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_HHMMA)}
                  </span>
                </Col>
                <Col xs={5} lg={6} xl={6} xxl="auto" className="shift-count">
                  <span className="lg-ps-2">{Strings.Schedule.CACount}</span>
                  <span>
                    {shiftDetails?.assignedCACount}/{shiftDetails?.totalCACount}
                  </span>
                </Col>
                <Col xs={5} lg={6} xl={6} xxl="auto" className="shift-openslot card-cell">
                  <span className="lg-ps-2">{Strings.Schedule.LDOpenslot}</span>
                  <span>
                    {shiftDetails?.lightDutyCount}/{shiftDetails?.lightDutyRequired}
                  </span>
                </Col>
                <Col xs={7} lg={6} xl={6} xxl="auto" className="openslot-main shift-count">
                  <span className="lg-ps-2">{Strings.Schedule.OpenSlot}</span>
                  <label htmlFor="Open slot">{renderOpenSlots()}</label>
                </Col>
                <Col xs={6} lg={6} xl={6} className="cardcell-for-mob pt-2">
                  <span className="pe-2 d-none d-lg-block">Status:</span>
                  <div className="status">
                    {shiftDetails?.published ? (
                      <span className="published">
                        <Published /> {Strings.Filter.Published}
                      </span>
                    ) : (
                      <span className="draft">
                        <Draft /> {Strings.Filter.Draft}
                      </span>
                    )}
                  </div>
                </Col>
                <Col xs={6} lg={6} xl={6} xxl="auto" className="openslot-main">
                  <span className="lg-ps-2">{Strings.Label.OnCallSlots}</span>
                  <span>
                    {shiftDetails?.onCallTotal}/{shiftDetails?.onCallRequired}
                  </span>
                </Col>
              </Row>
            </Col>
            <Row>
              <Col className="cardcell-for-web" xs="auto">
                <div className="d-flex align-items-center pt-1">
                  <span className="pe-2 d-none d-lg-block">Status:</span>
                  <div className="status">
                    {shiftDetails?.published ? (
                      <span className="published">
                        <Published /> {Strings.Filter.Published}
                      </span>
                    ) : (
                      <span className="draft">
                        <Draft /> {Strings.Filter.Draft}
                      </span>
                    )}
                  </div>
                </div>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button variant="outline-primary" type="submit" onClick={handleShiftDetailsClick}>
                  {Strings.Schedule.Details}
                </Button>
                {!scheduler && isCurrentTimeIsBeforeShiftStartTime(shiftDetails?.shiftFromTime || '') && renderAssignShiftButton()}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default ShiftCard;
