/**
 * @file   src\containers\Terms.tsx
 * @brief  Terms and Conditions page.
 * @date   Nov, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, Button, useNavigate, Row, Link } from '../components/ThirdPartyComponents';
import Strings from '../assests/strings/Strings.json';
import Logo from '../assests/images/logo-mobile.svg';
import { RoutePath } from '../utils/enums';

const Terms = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  return (
    <Col>
      <div className="privacy-header">
        {' '}
        <img src={Logo} alt="kki" width={200} />
      </div>
      <div className="container">
        <Row className="my-4">
          <Col>
            <h4>Terms and Conditions</h4>
          </Col>
          <Col xs="auto">
            {' '}
            <Button variant="secondary" onClick={() => navigate(RoutePath.Signup)}>
              {Strings.Button.Back}
            </Button>
          </Col>
        </Row>
        <p>
          When you subscribe to use the NBU automated scheduling app from Kennedy Krieger, you are signing up to receive SMS messages related to your schedule and messages about
          available open shifts from call outs. By agreeing to participate in SMS messages, you understand that electronic communications may not be secure. Further, you understand
          that data carriers may retain these messages.
        </p>

        <p>
          You can opt-out of SMS messages related to this application by opening your profile and unchecking the subscribe checkbox. You may also contact your supervisor and they
          can opt you out of receiving SMS messages at any time. You will no longer receive SMS messages once you have opt out from receiving them. If you want to join again,
          contact your administrator to receive SMS messages.
        </p>
        <p>
          If you are experiencing issues with the messaging program you can reach out to support at <Link to="mailto:carrollp@kennedykrieger.org">carrollp@kennedykrieger.org</Link>
          . Carriers are not liable for delayed or undelivered messages.
        </p>
        <p>Message and data rates may apply for any messages sent to you from us and to us from you. Message frequency may vary.</p>

        <p>
          Our Privacy Policy is{' '}
          <Link to="https://nbu.kennedykrieger.org/privacypolicy" target="_blank">
            https://nbu.kennedykrieger.org/privacypolicy
          </Link>
          . Contact us at <Link to="mailto:carrollp@kennedykrieger.org">carrollp@kennedykrieger.org</Link> for any technical issues related to this application.
        </p>
      </div>
    </Col>
  );
};

export default Terms;
