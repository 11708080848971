/**
 * @file   src\containers\Scheduler\ManageCallOut\ManageCallOutShift.tsx
 * @brief  Call-Out Shifts listing page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Row, Col, useState, useEffect, Modal, Button, moment, useLocation } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import CallOutCard from '../../../components/CallOutCard';
import KKISelect from '../../../components/KKISelect';
import { CURRENT_DATE_FORMAT_WITH_OFFSET, HTTP_STATUS_200, NUMBER_0, NUMBER_1, NUMBER_2, NUMBER_3, NUMBER_4 } from '../../../utils/constants';
import { IObject } from '../../../interfaces/generalInterface';
import { IShift } from '../../../interfaces/scheduleInterface';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getCallOutShifts, revertAllCalloutState } from '../../../store/actions/manageCallOutShiftAction';
import { RootState } from '../../../store';
import Loader from '../../../components/Loader';
import { MessageToaster } from '../../../utils/toastUtils';

// Toast object creation.
const toast = new MessageToaster();

const SCHManageCallOutShift = () => {
  // Create current location object.
  const location = useLocation();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access location state variables.
  const calloutFilterType = location.state?.calloutFilterType || null;

  // Access redux state variables.
  const { isFetchCallOutLoading, isFetchCallOutSuccess, fetchCallOutErrorCode, fetchCallOutMessage, callOutShifts } = useAppSelector((state: RootState) => state.manageCallOut);

  // Initialize component state variables.
  const [callOutCategory, setCallOutCategory] = useState<IObject | null>(calloutFilterType || { label: Strings.CalloutCategory.All, value: NUMBER_0.toString() });
  const [callOutMessageConfirmPopup, setCallOutMessageConfirmPopup] = useState<boolean>(false);
  const [exportConfirmPopup, setExportConfirmPopup] = useState<boolean>(false);

  // Component initial loading side effect handling.
  useEffect(() => {
    dispatch(getCallOutShifts({ callOutCategory: Number(callOutCategory?.value), currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }));
  }, [callOutCategory]);

  // Fetch callout shift api response state change.
  useEffect(() => {
    if (!isFetchCallOutSuccess && fetchCallOutErrorCode > HTTP_STATUS_200 && fetchCallOutMessage) {
      toast.toastError(fetchCallOutMessage);
      dispatch(revertAllCalloutState());
    }
  }, [isFetchCallOutSuccess, fetchCallOutErrorCode, fetchCallOutMessage]);

  // Sort category filter change event handler.
  const onCategorySelectChange = (val: IObject) => {
    setCallOutCategory(val);
  };

  // Send callon messages to CA confirmation event handler.
  const onConfirmCallOutMessaging = () => {
    // TODO
  };

  // Export CA list confirmation event handler.
  const onConfirmExportPdf = () => {
    // TODO
  };

  // Callout categories list.
  const category = [
    { label: Strings.CalloutCategory.All, value: NUMBER_0.toString() },
    { label: Strings.CalloutCategory.MorThanAShiftAway, value: NUMBER_1.toString() },
    { label: Strings.CalloutCategory.LessThanAShiftAway, value: NUMBER_2.toString() },
    { label: Strings.CalloutCategory.ninetyminAway, value: NUMBER_3.toString() },
    { label: Strings.CalloutCategory.sixtyminAway, value: NUMBER_4.toString() },
  ];

  return (
    <div>
      <div className="pageheader mb-0">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h1>{Strings.HD.ManageCallOutShifts}</h1>
          </Col>
          <Col xl={4} lg={5} md={6} xs={12} className="m-xs-16">
            <div className="d-flex align-items-center">
              <span className="pe-2">{Strings.Label.CallOutCategory}:</span>
              <Col>
                <KKISelect
                  id="Status"
                  name="Status"
                  className="custom-select"
                  placeholder={Strings.Label.CallOutCategory}
                  onSelectChange={onCategorySelectChange}
                  searchvalue={false}
                  options={category}
                  value={callOutCategory}
                />
              </Col>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        {callOutShifts &&
          callOutShifts.length > 0 &&
          callOutShifts.map((shift: IShift) => {
            return (
              <Col key={shift.shiftId} xl={6} lg={6} md={12}>
                <CallOutCard shiftDetails={shift} calloutFilterType={callOutCategory} />
              </Col>
            );
          })}
        {(!callOutShifts || (callOutShifts && callOutShifts.length === 0)) && <div className="mt-5 text-center w-100">{Strings.Text.NoDataFound}</div>}
      </Row>
      {isFetchCallOutLoading && <Loader />}
      <Modal show={callOutMessageConfirmPopup} onHide={() => setCallOutMessageConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.CallOutMessagingConfirmText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => setCallOutMessageConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onConfirmCallOutMessaging()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={exportConfirmPopup} onHide={() => setExportConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.ExportPdfConfirmText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => setExportConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onConfirmExportPdf()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SCHManageCallOutShift;
