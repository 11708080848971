/**
 * @file   src\containers\PrivacyPolicy.tsx
 * @brief  Page not found page.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, Button, useNavigate, Row, Link } from '../components/ThirdPartyComponents';
import Strings from '../assests/strings/Strings.json';
import Logo from '../assests/images/logo-mobile.svg';
import { RoutePath } from '../utils/enums';

const PrivacyPolicy = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  return (
    <Col>
      <div className="privacy-header">
        {' '}
        <img src={Logo} alt="kki" width={200} />
      </div>
      <div className="container">
        <Row className="my-4">
          <Col>
            <h4>{Strings.HD.PrivacyPolicy}</h4>
            <small>Updated November 21, 2024</small>
          </Col>
          <Col xs="auto">
            {' '}
            <Button variant="secondary" onClick={() => navigate(RoutePath.Signup)}>
              {Strings.Button.Back}
            </Button>
          </Col>
        </Row>
        <h5>Overview</h5>
        <p>Kennedy Krieger Institute (“Kennedy Krieger,” or “the Institute”) is committed 
to protecting the privacy and security of the personal information we receive 
or collect from you. We also believe in transparency and are committed to 
informing you about how we treat your personal information.</p>

<p>Kennedy Krieger has prepared this Privacy Policy (the "Policy"), together with 
any documents referenced therein, to inform you ("you", "your" or "user") of 
our practices regarding personal information that we collect, use, and share 
regarding our websites, and other online services provided by us and our 
affiliates. The Policy also describes certain rights and options that you have 
with regard to your personal information.</p>
<p>This Policy covers all personal information collected through website URLs, 
web applications, and mobile applications (collectively the "Sites").</p>
<p>This Policy does not apply to information you may provide to us offline or 
through means other than through these Websites.</p>
<p className="fw-sb">
Please read this Privacy Policy carefully to understand how we treat your 
personal information and what choices and rights you have in this regard. 
If you do not agree with the terms and conditions of this Policy, you 
should not access or use our sites or our online services.
</p>
<h5>Who is responsible for your personal information?</h5>
<p>Kennedy Krieger is responsible for the personal information you provide 
through our Sites.</p>
<p>This NBU scheduling application will only use data and personal information 
from internal employees that have already agreed to the Kennedy Krieger
policy and procedures shown in the following link: 
(<Link to="https://www.kennedykrieger.org/about-us/policies-standards" target='_blank'>https://www.kennedykrieger.org/about-us/policies-standards</Link>). Employees 
are aware of the safety and protections in place by the Institute to protect 
their personal information. Employees can opt-out of the application at any 
time by editing their profile to unsubscribe from the application and to be 
removed from the SMS text messaging system.</p>
<h5>What personal information do we collect and why</h5>
<p>
The NBU Scheduling Application may collect and process the following 
personal information from you for the following purposes for our KKI internal 
staff only:
<ul>
  <li>Contact Information - When you visit our Site(s) or ask for services, we 
may ask you for your name, address, telephone number, email address 
or other contact details in order to respond to your requests or 
inquiries.</li>
</ul>
</p>
<h5>How we will use your personal information?</h5>
<ul>
<li>Kennedy Krieger issued email addresses for employees will be used to 
send emails to Kennedy Krieger staff using sendgrid to internal 
employees only. </li>
<li>Phone numbers will be used to send SMS text messages through the 
Twilio app to notify staff of upcoming appointments or to notify staff of 
open shifts that are available.</li>
<li>Personal information can be edited in each employee’s profile within 
this secure website.</li>
<li>Used for staffing purposes and maintaining scheduled appointments 
for employee shifts.</li>
<li>The scheduling application uses a secure HTTPS connection and 
encrypts the employee's personal identifiable information</li>
</ul>
<h5>Disclosure of your personal information</h5>
<p>We may share your personal information in the following context:</p>
<p>Third parties – Kennedy Krieger Institute may provide personal information 
about you to third parties that offer products and services pertaining to the 
use of the scheduling application. ZCO Corporation developed this 
scheduling application and may have access to personal information for 
employees only during the implementation phase of this application</p>
<h5>How long do we store your personal information?</h5>
<p>Kennedy Krieger will retain your personal information as needed to fulfill the 
purposes for which it was collected. The Institute will retain and use your 
personal information as long as necessary to comply with our business 
requirements, legal obligations, resolve disputes, protect our assets, provide 
our services, and enforce our agreements.</p>
<p>When we no longer have a purpose to retain your personal information, we 
will securely destroy your personal information in accordance with applicable 
law and our policies.</p>
<h5>Security of your personal information</h5>
<p>Kennedy Krieger has put in place appropriate security measures to prevent 
your personal information from being accidentally lost, used, altered, 
disclosed or accessed in an unauthorized manner. While our security 
measures seek to protect your personal information in our possession, no 
security system is perfect, and the Institute cannot promise that your 
personal information will remain absolutely secure in all circumstances.</p>
<p>The safety and security of your personal information also depends on you. 
Where you use a password for access to restricted parts of our Sites, you are 
responsible for keeping the password confidential. Do not share your 
password with anyone.</p>
<h5>Updating your personal information</h5>
<p>If you have personal information that needs to be changed, please contact 
your supervisor to have name, phone number, email address updated within 
our system. You may also edit your account details if you have a user account 
through the scheduling application via the profile edit section.  </p>
<h5>Changes to our privacy policy</h5>
<p>We reserve the right to update and change this Policy from time to time in 
order to reflect any changes to the way in which we treat your personal 
information or in response to changes in law. We will post any changes we 
make to this Policy on this page. If we make material changes to how we 
treat your personal information, we will notify you through a notice on the 
website home page or through email communication, when appropriate. The 
date this Policy was last revised is identified at the top of this Policy.</p>
<h5>Contact Details</h5>
<p>To view the Kennedy Krieger Institute policies and procedures, please click 
the link shown here: <Link to="https://www.kennedykrieger.org/about-us/policies-standards" target='_blank'>https://www.kennedykrieger.org/about-us/policies-standards.</Link></p>
<p>If you’re experiencing issues with the messaging program, please contact 
support at <Link to="mailto:carrollp@kennedykrieger.org">carrollp@kennedykrieger.org.</Link></p>
      </div>
    </Col>
  );
};

export default PrivacyPolicy;
