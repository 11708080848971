/**
 * @file   src\containers\CA\Schedule\ManageShifts.tsx
 * @brief  This file is responsible for managing and self assign shifts for CA.
 * @date   Jul, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Loader from '../../../components/Loader';
import { Col, Row, useState, moment, useEffect, Modal, Button, useLocation } from '../../../components/ThirdPartyComponents';
import { IAssignCARequest, IGetAllShiftsRequest, IShift } from '../../../interfaces/scheduleInterface';
import Strings from '../../../assests/strings/Strings.json';
import KKIDatepicker from '../../../components/KKIDatepicker';
import ShiftTypes from '../../../components/commonComponents/ShiftTypes';
import ShiftCard from '../../../components/ShiftCard';
import KKISelect from '../../../components/KKISelect';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getCalculatedDateTime } from '../../../utils/helper';
import { DateTimeCalculatedTypes, OperationTypes, ScheduleSortBy } from '../../../utils/enums';
import { DATE_FORMAT_YYYY_MM_DD, HTTP_STATUS_200, NUMBER_0, NUMBER_1, NUMBER_6, DEAULT_TIME_FORMAT, CURRENT_DATE_FORMAT_WITH_OFFSET } from '../../../utils/constants';
import { assignCA, getAllShifts, getAllShiftTypes, revertAllScheduleState } from '../../../store/actions/manageCAScheduleAction';
import { RootState } from '../../../store';
import { IObject } from '../../../interfaces/generalInterface';
import { MessageToaster } from '../../../utils/toastUtils';
import { getProfile } from '../../../store/actions/userAction';
import { PROFILE_FETCH_FAILD } from '../../../messages/validationMessages';
import { isShiftDateISHoliday, revertAllCommonState } from '../../../store/actions/commonAction';

// Toast object creation.
const toast = new MessageToaster();

const ManageShifts = () => {
  // Create current location object.
  const location = useLocation();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access location state variables.
  const filterItemsData = location.state?.filterItems || null;

  // Access redux state variables.
  const {
    isGetAllShiftSuccess,
    getAllShiftErrorCode,
    getAllShiftMessage,
    isGetAllShiftLoading,
    shiftsList,
    isShiftTypesSuccess,
    shiftTypesErrorCode,
    shiftTypes,
    shiftTypesMessage,
    isShiftTypesLoading,
    isAssignShiftSuccess,
    assignShiftErrorCode,
    assignShiftMessage,
    isAssignShiftLoading,
  } = useAppSelector((state: RootState) => state.manageCASchedule);
  const { isShiftHolidayLoading, isShiftHolidaySuccess, shiftHolidayErrorCode, shiftHolidayMessage, isHoliday } = useAppSelector((state: RootState) => state.common);

  // Initialize component state variables.
  const [startDateSelected, setStartDateSelected] = useState<Date | null>(filterItemsData ? filterItemsData.startDateSelected : new Date());
  const [endDateSelected, setEndDateSelected] = useState<Date | null>(
    filterItemsData ? filterItemsData.endDateSelected : getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Add, NUMBER_6, moment(startDateSelected).toDate()),
  );
  const [selectedShiftTypeIds, setSelectedShiftTypeIds] = useState<number[]>(filterItemsData ? filterItemsData.selectedShiftTypeIds : []);
  const [selectedPublishStatus, setSelectedPublishStatus] = useState<number[]>(filterItemsData ? filterItemsData.selectedPublishStatus : [NUMBER_1, NUMBER_0]);
  const [sortByFilter, setSortByFilter] = useState<IObject | null>(
    filterItemsData?.sortByFilter || {
      label: ScheduleSortBy[ScheduleSortBy.Shift_Timing].replace(/_/g, ' '),
      value: ScheduleSortBy.Shift_Timing.toString(),
    },
  );
  const [shiftsListData, setShiftsListData] = useState<IShift[]>([]);
  const [pageSize] = useState<number>(NUMBER_0);
  const [pageIndex] = useState<number>(NUMBER_0);
  const [selectedShiftId, setSelectedShiftId] = useState<number | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(null);
  const [isOnCallAction, setIsOnCallAction] = useState<boolean>(false);
  const [assignPopup, showAssignPopup] = useState<boolean>(false);
  const [holidayConfirmPopup, showHolidayConfirmPopup] = useState<boolean>(false);
  const [filterItems, setFilterItems] = useState<any>(null);

  // Method to fetch shifts.
  const fetchShifts = () => {
    const shiftRequest: IGetAllShiftsRequest = {
      startDate: moment(startDateSelected).format(DATE_FORMAT_YYYY_MM_DD) + DEAULT_TIME_FORMAT,
      endDate: moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD) + DEAULT_TIME_FORMAT,
      published: selectedPublishStatus,
      shiftType: selectedShiftTypeIds,
      sortBy: sortByFilter?.value ? Number(sortByFilter?.value) : 1,
      page: pageIndex,
      limit: pageSize,
    };
    setFilterItems((prev: any) => ({
      ...prev,
      startDateSelected,
      endDateSelected,
      selectedShiftTypeIds,
      selectedPublishStatus,
      sortByFilter,
    }));
    dispatch(getAllShifts(shiftRequest));
  };

  // Component initial loading side effect handler.
  useEffect(() => {
    if (selectedShiftTypeIds?.length > 0) {
      fetchShifts();
    }
  }, [shiftTypes, isAssignShiftSuccess, startDateSelected, endDateSelected, selectedPublishStatus, selectedShiftTypeIds, sortByFilter]);

  //  Dispatch an action to simulate shift type data fetching and user profile details for assign shift and Reset shiftsListData on unmount
  useEffect(() => {
    dispatch(getAllShiftTypes());
    // On unmount reset states
    return () => {
      dispatch(revertAllScheduleState());
      dispatch(revertAllCommonState());
    };
  }, []);

  // Shift type response state change.
  useEffect(() => {
    if (isShiftTypesSuccess && shiftTypesErrorCode === HTTP_STATUS_200 && shiftTypes) {
      const shiftIds = shiftTypes.map((shift) => shift.shiftId);
      if (!filterItemsData?.selectedShiftTypeIds || (filterItemsData?.selectedShiftTypeIds && filterItemsData?.selectedShiftTypeIds.length === NUMBER_0)) {
        setSelectedShiftTypeIds(shiftIds);
      }
    } else if (!isShiftTypesSuccess && shiftTypesErrorCode > HTTP_STATUS_200 && shiftTypesMessage) {
      // toast.toastError(shiftTypesMessage);
    }
  }, [isShiftTypesSuccess, shiftTypesErrorCode, shiftTypes, shiftTypesMessage]);

  // Shifts response state change.
  useEffect(() => {
    if (isGetAllShiftSuccess && getAllShiftErrorCode === HTTP_STATUS_200 && shiftsList) {
      setShiftsListData(shiftsList?.shiftInfo);
    } else if (!isGetAllShiftSuccess && getAllShiftErrorCode > HTTP_STATUS_200 && getAllShiftMessage) {
      // toast.toastError(getAllShiftMessage);
    }
  }, [isGetAllShiftSuccess, getAllShiftErrorCode, getAllShiftMessage, shiftsList]);

  // Assign CA response state change.
  useEffect(() => {
    if (isAssignShiftSuccess && assignShiftErrorCode === HTTP_STATUS_200 && assignShiftMessage) {
      toast.toastSuccess(assignShiftMessage);
    } else if (!isAssignShiftSuccess && assignShiftErrorCode > HTTP_STATUS_200 && assignShiftMessage) {
      toast.toastError(assignShiftMessage);
    }
  }, [isAssignShiftSuccess, assignShiftErrorCode, assignShiftMessage]);

  // Shift holiday checking api response state change.
  useEffect(() => {
    if (isShiftHolidaySuccess && shiftHolidayErrorCode === HTTP_STATUS_200 && shiftHolidayMessage) {
      if (isHoliday) {
        showHolidayConfirmPopup(true);
      } else {
        showAssignPopup(true);
      }
      dispatch(revertAllCommonState());
    } else if (!isShiftHolidaySuccess && shiftHolidayErrorCode > HTTP_STATUS_200 && shiftHolidayMessage) {
      toast.toastError(shiftHolidayMessage);
      dispatch(revertAllCommonState());
    }
  }, [isShiftHolidaySuccess, shiftHolidayErrorCode, shiftHolidayMessage, isHoliday]);

  // Sort select change event handler.
  const onSortSelectChange = (val: IObject) => {
    setSortByFilter(val);
  };

  // Start date filter input select event handler.
  const handleStartDateFilterChange = (date: Date | null) => {
    if (date !== null) {
      setStartDateSelected(date);
      if (endDateSelected !== null && moment(date).isAfter(moment(endDateSelected))) {
        setEndDateSelected(date);
      }
    }
  };

  // End date filter input select event handler.
  const handleEndDateFilterChange = (date: Date | null) => {
    if (date !== null) {
      setEndDateSelected(date);
    }
  };

  // Apply button event handler.
  const handleApply = (filters: { shiftTypeIds: number[]; publishStatus: number[] }) => {
    setSelectedShiftTypeIds(filters.shiftTypeIds);
    setSelectedPublishStatus(filters.publishStatus);
  };

  // Open confirmation modal for assign action
  const onAssignClick = (shiftId: number, startDate: string | null, isOnCall: boolean) => {
    // get profile api call for assign shift colorgroup and islightduty request
    setSelectedShiftId(shiftId);
    setSelectedStartDate(startDate);
    setIsOnCallAction(isOnCall);
    if (startDate) {
      dispatch(isShiftDateISHoliday({ holidayDate: startDate?.split('T')[0] || '' }));
    } else {
      showAssignPopup(true); // Show confirmation modal
    }
  };

  // Callback function to handle "Assign Shift" or "Assign On-Call Shift" based on confirmation
  const handleAssignShift = (shiftId: number, startDate: string | null, isOnCall: boolean) => {
    dispatch(getProfile()).then((result) => {
      if (getProfile.fulfilled.match(result)) {
        const fetchedProfile = result.payload?.info;
        const requestParams: IAssignCARequest = {
          Cas: [{ caId: fetchedProfile?.userId, ColorCode: Number(fetchedProfile?.colorGroup), OnCall: isOnCall, IsLD: fetchedProfile?.lightDuty }],
          shiftId,
          shiftStart: startDate,
          currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
          overrideColorCode: false,
        };
        dispatch(assignCA(requestParams));
      } else {
        toast.toastError(PROFILE_FETCH_FAILD);
      }
    });
  };

  // Handle modal confirmation
  const confirmAssignShift = () => {
    if (selectedShiftId !== null) {
      handleAssignShift(selectedShiftId, selectedStartDate, isOnCallAction);
    }
    // Hide confirmation modal
    showAssignPopup(false);
    showHolidayConfirmPopup(false);
  };

  // Sort by filter options.
  const sortby = [
    { label: ScheduleSortBy[ScheduleSortBy.Shift_Timing].replace(/_/g, ' '), value: ScheduleSortBy.Shift_Timing.toString() },
    { label: ScheduleSortBy[ScheduleSortBy.Open_Slot].replace(/_/g, ' '), value: ScheduleSortBy.Open_Slot.toString() },
    { label: ScheduleSortBy[ScheduleSortBy.LD_Open_Slot].replace(/_/g, ' '), value: ScheduleSortBy.LD_Open_Slot.toString() },
  ];

  return (
    <div>
      <div className="filter-main row-filter">
        <Row className="d-flex justify-content-between">
          <Col xl={6} md={7} sm={12}>
            <Row className="align-items-center">
              <Col xs className="calendar-mob-left">
                <KKIDatepicker
                  id="kkidatepicker"
                  name="kkidatepicker"
                  placeholder="Start Date"
                  value={startDateSelected}
                  dateFormat="dd MMM yyyy"
                  onChangeDatepicker={(date: any) => handleStartDateFilterChange(date)}
                />
              </Col>
              <Col xs="auto" className="px-0">
                {Strings.Schedule.DateFilterText}
              </Col>
              <Col xs className="calendar-mob-right">
                <KKIDatepicker
                  id="kkidatepicker"
                  name="kkidatepicker"
                  placeholder="End Date"
                  value={endDateSelected}
                  dateFormat="dd MMM yyyy"
                  minDate={startDateSelected || moment().toDate()}
                  onChangeDatepicker={(date: any) => handleEndDateFilterChange(date)}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={4} md={5} sm={12} className="filter-sort">
            <Row>
              <Col>
                <Row className="d-flex align-items-center">
                  <Col xs="auto" className="pe-0">
                    <span>{Strings.Filter.Sort}</span>
                  </Col>
                  <Col className="pe-0">
                    <KKISelect
                      id="signupselect"
                      name="signupselect"
                      className="custom-select"
                      placeholder={Strings.Sort.Select}
                      onSelectChange={onSortSelectChange}
                      searchvalue={false}
                      options={sortby}
                      defaultValue={sortByFilter}
                      value={sortByFilter}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" className="btn-container d-flex">
                <ShiftTypes
                  selectedShiftTypeIds={selectedShiftTypeIds}
                  selectedShiftStatus={selectedPublishStatus}
                  leaveStatus={false}
                  onChange={handleApply}
                  shiftTypes={shiftTypes}
                  isRosterView={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row>
        {shiftsListData &&
          shiftsListData?.length > 0 &&
          shiftsListData.map((shift: IShift) => (
            <Col xl={6} md={12} sm={12} key={shift.shiftId}>
              <ShiftCard shiftDetails={shift} filterItems={filterItems} onAssignShift={onAssignClick} />
            </Col>
          ))}
        {!shiftsListData || (shiftsListData && shiftsListData?.length === 0 && <div className="mt-5 text-center w-100">{Strings.Text.NoDataFound}</div>)}
      </Row>

      <Modal show={assignPopup} onHide={() => showAssignPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{isOnCallAction ? Strings.Text.AssignAsOnCallPopText : Strings.Text.AssignPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showAssignPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={confirmAssignShift} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal show={holidayConfirmPopup} onHide={() => showHolidayConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.HolidayConfirmPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showHolidayConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={confirmAssignShift} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      {(isShiftHolidayLoading || isGetAllShiftLoading || isShiftTypesLoading || isAssignShiftLoading) && <Loader />}
    </div>
  );
};

export default ManageShifts;
